<template lang="pug">
  #Cover
    header.header.d-flex.text-left
      h1.title.align-self-center.uppercase.boldest.neutral-color
        span.accent-back La industria
        br
        span.accent-back indumentaria en datos
    section.intro.container.d-flex
        p.default-color.align-self-center.text-center Las tragedias que han vivido las trabajadoras de la industria indumentaria en el mundo han llamado la atención de organizaciones nacionales e internacionales. En algunas regiones se ha avanzado en la defensa de los derechos de las trabajadoras de la industria pero en otras, como Latinoamérica, aún tenemos muy poca información al respecto. Este proyecto de investigación fue un primer acercamiento a la industria indumentaria en México. En este visualizador de datos es posible consultar las condiciones laborales y la concentración geográfica de la industria del vestido en el país.
    section.d-flex.justify-content-center.mb-5
      b-button.download-btn.my-5.px-2(href="/assets/ConvocatoriaPeriodistasArtistasFundaciónAvina.pdf" target="_blank") Participa en la convocatoria
    
</template>
<script>
export default {
  name: "Cover",
};
</script>
<style lang="scss">
#Cover {
  .header {
    min-height: 85vh;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.52),
        rgba(0, 0, 0, 0.52)
      ),
      url("/assets/imgs/Datos_Header.png") no-repeat;
    background-size: cover;
    justify-content: start;
    .title {
      font-size: 3rem;
      padding: 1.5rem 4rem;
      span {
        padding-inline: 1.5rem;
      }
    }
  }
  .intro {
    margin: 8rem auto auto auto;
  }
}
@media (max-width: 767px) {
  #Cover {
    .header {
      justify-content: center;
      .title {
        font-size: 2.1rem !important;
        padding: 1.5rem 1rem;
        span {
          padding-inline: 0;
        }
      }
    }
    .intro {
      font-size: 1rem !important;
    }
  }
}
</style>
