<template lang="pug">
#HexMap
  #chart1
</template>
<script>
import * as d3 from "d3";

export default {
  name: "hexmap",
  data() {
    return {
      json: {},
      config: {
        margin: {
          top: 0,
          right: 0,
          bottom: 50,
          left: 0
        },
        size: {
          width: 100,
          height: 400
        }
      },
      bindTo1: "chart1",
      chart1: {}
    };
  },
  computed: {
    dataSet() {
      return this.$store.state.filteredMapDataSet;
    }
  },
  watch: {
    dataSet() {
      this.setChart();
    }
  },
  methods: {
    async getJson() {
      this.json = await d3.json("/assets/json/mx_hexgrid.json");
    },
    setChart() {
      if (d3.select("#HexMap").node()) {
        this.config.size.width =
          d3
            .select("#HexMap")
            .node()
            .getBoundingClientRect().width -
          this.config.margin.left -
          this.config.margin.right;
      }

      this.config.size.height =
        550 - this.config.margin.top - this.config.margin.bottom;
      const width = this.config.size.width,
        height = this.config.size.height,
        margin = this.config.margin;

      d3.select("#" + this.bindTo1 + " svg").remove();

      const svg1 = d3
        .select("#" + this.bindTo1)
        .append("svg")
        .attr("width", width + margin.left)
        .attr("height", height + margin.bottom + margin.top);

      const g1 = svg1
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      this.chart1["svg"] = g1;

      this.drawHexMap();
    },
    drawHexMap() {
      let projection = d3
        .geoMercator()
        .scale(1150)
        .translate([2350, 400]);
      let path = d3.geoPath().projection(projection);

      let data = d3.rollup(
        this.dataSet,
        v => (d3.sum(v, d => d.subtotal) / d3.sum(v, d => d.total)) * 100,
        d => d.state_code
      );
      let data_total = d3.rollup(
        this.dataSet,
        v => d3.sum(v, d => d.subtotal),
        d => d.state_code
      );
      let minVal = d3.least(data, ([, entry]) => entry)[1],
        maxVal = d3.least(data, ([, entry]) => -entry)[1];

      let color = d3
        .scaleQuantile()
        .range([
          "#e0ebf1",
          "#d4e2ec",
          "#94b8d0",
          "#75a4c2",
          "#4584ad",
          "#00578e"
        ])
        .domain([0, maxVal]);

      let tooltip = d3
        .select("body")
        .append("div")
        .attr("class", "tooltip")
        .style("opacity", 0);

      this.chart1.svg
        .append("g")
        .selectAll("path")
        .data(this.json.features)
        .enter()
        .append("path")
        .attr("d", path)
        .attr("class", "unit")
        .attr("fill", d =>
          isNaN(data.get(d.properties.state_code.toString()))
            ? "#DEDEDE"
            : color(data.get(d.properties.state_code.toString()))
        )
        .on("mouseover", (event, d) => {
          tooltip
            .transition()
            .duration(100)
            .style("opacity", 0.8);
          tooltip
            .html(
              `<b>${d.properties.state_name}</b> <br> Porcentaje: ${
                isNaN(data.get(d.properties.state_code.toString()))
                  ? "No Disponible"
                  : data.get(d.properties.state_code.toString()).toFixed(1) +
                    "%"
              }<br> Total: ${
                isNaN(data_total.get(d.properties.state_code.toString()))
                  ? "No Disponible"
                  : data_total.get(d.properties.state_code.toString())

              }`
            )
            .style("left", event.pageX - 78 + "px")
            .style("top", event.pageY - 28 + "px");
        })
        .on("mouseout", function() {
          tooltip
            .transition()
            .duration(500)
            .style("opacity", 0);
        });

      // Add the labels
      this.chart1.svg
        .append("g")
        .selectAll("labels")
        .data(this.json.features)
        .enter()
        .append("text")
        .attr("x", function(d) {
          return path.centroid(d)[0];
        })
        .attr("y", function(d) {
          return path.centroid(d)[1];
        })
        .text(function(d) {
          return d.properties.state_abbr;
        })
        .attr("class", "map-label")
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "central")
        .attr("fill", d =>
          data.get(d.properties.state_code.toString()) > maxVal / 2
            ? "#fff"
            : "#6d6e71"
        );

      //add legend
      const minColor = "#e0ebf1",
        maxColor = "#00578e";
      const legendX = this.config.size.width - 200;
      const legendY = this.config.size.height;

      const defs = this.chart1.svg.append("defs");
      const linearGradient = defs
        .append("linearGradient")
        .attr("id", "linear-gradient");

      linearGradient
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%");

      linearGradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", minColor);
      linearGradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", maxColor);

      const legend = this.chart1.svg.select("g").attr("class", "legend");
      legend
        .append("rect")
        .attr("x", legendX)
        .attr("y", legendY - 25)
        .attr("width", 180)
        .attr("height", 25)
        .style("fill", "url(#linear-gradient)");
      const scaleTicks = d3
        .scaleLinear()
        .domain([minVal, maxVal])
        .range([0, 180]);
      const axisLegend = d3
        .axisBottom(scaleTicks)
        .tickSize(0)
        .tickValues([minVal, (maxVal - minVal) / 2, maxVal]);

      legend
        .append("text")
        .attr("class", "legend-text")
        .attr("x", legendX)
        .attr("y", legendY - 28)
        .text("Porcentaje (%)");

      legend
        .attr("class", "legend-text")
        .append("g")
        .attr("transform", "translate(" + legendX + "," + legendY + ")")
        .call(axisLegend)
        .call(g => g.select(".domain").remove());
    }
  },
  async mounted() {
    await this.getJson();
    this.setChart();
  }
};
</script>
<style lang="scss">
#HexMap {
  .map-label {
    font-size: 10px;
    font-weight: bold;
  }
}
div.tooltip {
  position: absolute;
  pointer-events: none;
  background: #3d3d3d;
  border-radius: 2px;
  color: #fff;
  max-width: 180px;
  text-align: center;
  justify-content: center;
  padding: 10px;
  font-size: 13px;
  font-weight: bold;
}
</style>
