<template lang="pug">
#SelectChart
  b-button.select-btn( @click="setChartType('bar')" variant="link")
    img(v-if="chartType !== 'bar'" src="/assets/imgs/Icon_Barras_gris.svg")
    img(v-if="chartType == 'bar'" src="/assets/imgs/Icon_Barras_azul.svg")
  b-button.select-btn( @click="setChartType('heatmap')" variant="link")
    img(v-if="chartType !== 'heatmap'" src="/assets/imgs/Icon_Heatmap_gris.svg")
    img(v-if="chartType == 'heatmap'" src="/assets/imgs/Icon_Heatmap_azul.svg")
  b-button.select-btn(@click="setChartType('map')" variant="link")
    img(v-if="chartType !== 'map'" src="/assets/imgs/Icon_Map_gris.svg")
    img(v-if="chartType == 'map'" src="/assets/imgs/Icon_Map_azul.svg")
  
  
</template>
<script>
export default {
  name: "SelectChart",
  computed: {
    chartType: {
      get() {
        return this.$store.state.chartType;
      },
      set(value) {
        this.$store.commit("setChartType", value);
      }
    }
  },
  methods: {
    setChartType(value) {
      this.chartType = value;
    }
  }
};
</script>
<style lang="scss">
#SelectChart {
  margin-top: 50px;
  .select-btn {
    padding: 0;
    margin: 0.25rem auto;
    img {
    width: 100%;
    height: auto;
    max-width: 50px;
    }
  }
}
</style>
