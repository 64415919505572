<template lang="pug">
#Home
  Cover
  b-container.max-container(fluid)
    b-row(v-if="!isMobile")
      b-col.px-0#filtersColumn(cols="12" sm="4" md="4" lg="3")
        Filters
      b-col.px-0(cols="12" sm="8" md="8" lg="9")
        b-container(fluid)
          b-row.grey-back
            b-col#chartsCol.px-0(cols="12" sm="11" md="11" lg="11")
              Charts
            b-col#selectChartColumn.pl-0(cols="12" sm="1" md="1" lg="1")
              SelectChart
          b-row#downloadRow
            b-col(cols="12" md="12" lg="12")
              Download
    b-row.mobile-msg.d-flex.justify-content-center.default-back.text-center.mx-2.mb-5.mt-3(v-if="isMobile" align-v="center")
      b-col.default-back(cols="12")
        img(src="/assets/imgs/escritorio.png")
        p.neutral-color.bold Cambia a escritorio para navegar el interactivo
  
  
</template>

<script>
import Cover from "@/components/Cover.vue";
import Filters from "@/components/Filters.vue";
import Charts from "@/components/Charts.vue";
import SelectChart from "@/components/SelectChart.vue";
import Download from "@/components/Download.vue";

export default {
  name: "Home",
  components: {
    Cover,
    Filters,
    Charts,
    SelectChart,
    Download
  },
  data() {
    return {
      isMobile: false,
    }
  },
  methods: {
    mobileCheck() {
      var check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c( | )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

  },
   mounted() {
    this.isMobile = this.mobileCheck();
  }

};
</script>

<style lang="scss">
.mobile-msg {
    font-size: 1rem;
    min-height: 30vh;
    img {
      max-width: 30%;
      height: auto;
    }
  }
.max-container {
      max-width: 1800px;
}
.mobile-msg{
    border-radius: 2px;
    b-col {
      height: 100%;
    }
}
</style>
