<template lang="pug">
  #cap-2.cap.section
    #cap-2sub1.subsection-cap
      h2(id='metodología') Metodología
      p
        | Para el análisis 
        strong cuantitativo
        |  de este proyecto de investigación, utilizamos las siguientes bases de datos:
      ul
        li
          p
            strong Encuesta Nacional de Ocupación y Empleo
            | : La Encuesta Nacional de Ocupación y Empleo (ENOE) es la mejor fuente de información que tenemos para hablar sobre las condiciones laborales en México. Esta encuesta se publica trimestralmente y la nueva versión está disponible aproximadamente un mes después de que termina cada trimestre del año. La encuesta tiene representatividad estatal y nacional. Este proyecto de investigación se realizó durante el año 2020, durante la pandemia de SARS COV 2. En el año 2020, sólo la ENOE del primer trimestre del año se realizó de manera presencial. Posteriormente, la ENOE se realizó por vía telefónica; dando lugar a una nueva versión de este instrumento al que ahora se conoce como la Encuesta Telefónica de Ocupación y Empleo (ETOE). Debido a que las variables cambian ligeramente, en este proyecto utilizamos la última ENOE del primer trimestre de 2020 para medir todo lo relacionado con las condiciones laborales de las personas que trabajan en la industria indumentaria. Al procesar estos datos pudimos generar estimaciones para calcular el número de empleados en la industria indumentaria mexicana que tienen acceso a prestaciones de ley, instituciones de salud, uniones sindicales, entre otros beneficios. Asimismo, en la segunda sección del proyecto, utilizamos las versiones históricas de la ENOE para procesar todos los datos disponibles desde el primer trimestre de 2005 hasta el primer trimestre de 2020. Con esta información pudimos obtener el porcentaje de personas que trabajan para la industria indumentaria y cruzar dicha información con variables de salud, pobreza y violencia.
        li
          p
            strong Directorio Estadístico Nacional de Unidades Económicas
            | : El Directorio Estadístico Nacional de Unidades Económicas (DENUE) contiene los datos de identificación, ubicación, actividad económica y tamaño de todas las unidades económicas activas en el territorio nacional. En este proyecto de investigación, utilizamos datos del DENUE para identificar dónde están ubicadas las unidades económicas que se dedican a la industria indumentaria, de qué tamaño y el número de empleos que generan en cada municipio.
        li
          p
            strong Egresos hospitalarios de la Secretaría de Salud
            | : La base de datos de egresos hospitalarios de la Secretaría de Salud contiene todos los egresos hospitalarios que ocurrieron en todos los hospitales de la Secretaría de Salud del país. En este sentido, nos permite identificar cuántas personas ingresaron al hospital por afecciones respiratorias, enfermedades frecuentemente vinculadas con la presencia de la industria indumentaria. Con base en esta información pudimos generar una tasa municipal de ingresos hospitalarios por afecciones respiratorias.
        li
          p
            strong Estimaciones de pobreza
            | : Asimismo, durante este proceso trabajamos con las estimaciones de pobreza generadas por el Consejo Nacional para la Evaluación de la Política Social (CONEVAL). Cada cinco años, el CONEVAL utiliza el Censo Nacional de Población y Vivienda o la Encuesta Intercensal para generar estimaciones de pobreza a nivel municipal. Para este proyecto, utilizamos las estimaciones de pobreza, pobreza moderada y pobreza extrema generadas por dicha institución.
        li
          p
            strong Datos de homicidios del INEGI
            | : También utilizamos los datos de homicidios que publica cada año el Instituto Nacional de Estadística y Geografía (INEGI). Esta base de datos es el registro más completo que tenemos sobre los homicidios en México, ya que es construida a partir de las actas y certificados defunción generados por el Registro Civil. Con esta base, generamos tasas municipales de homicidio de hombres y mujeres que pudimos cruzar con la presencia de la industria indumentaria.
        li
          p
            strong Incidencia delictiva del SESNSP
            | : Finalmente, utilizamos los datos de incidencia delictiva que publica el Secretariado Ejecutivo del Sistema Nacional de Seguridad Pública desde el año 2015. Con el número de carpetas abiertas pudimos calcular las tasas municipales de carpetas abiertas por los delitos de lesiones, homicidio, secuestro y violación.
      p
        | Para el análisis 
        strong cualitativo
        | , se realizó lo siguiente.
      ul
        li
          p
            | La identificación de las principales temáticas en el debate público sobre la industria fue realizada utilizando herramientas abiertas para el análisis de contenidos periodísticos (MIT CCM & Berkman Klein Center, 2020).
        li
          p
            | Los temas críticos del sector fueron complementados realizando un mapeo de los principales estudios y fuentes de información disponibles y confeccionadas por diferentes organizaciones de la sociedad civil activas en el tema.
        li
          p
            | A partir de dos fuentes -periodísticas e informes de OSCs- se procedieron a identificar los actores principales y sus formas de presencia virtual -páginas web y redes sociales- para luego observar a partir de estos puntos de partida las conexiones existentes con otros agentes activos y con intereses en la temática (SciencePo MédiaLab, 2020).
        li
          p
            | De acuerdo con los intereses de este estudio, se evaluó la relevancia de los actores detectados y se los incorporó al mapeo de actores.
        li
          p
            | Por último, el mapeo de actores fue enriquecido de forma iterativa durante toda la realización de las entrevistas utilizando la técnica de ‘bola de nieve’ o 
            em ‘snowballing’
            | , solicitando a los informantes potenciales referentes en el tema y nuevos entrevistados para el informe.
        li
          p
            | Se contactó a las organizaciones identificadas y se coordinaron entrevistas de forma virtual, atendiendo a las restricciones de la Jornada Nacional de Sana Distancia.
        li
          p
            | Como resultado, se realizaron diecisiete entrevistas entre julio y octubre de 2020 de forma telefónica y mediada por computadora.
        li
          p
            | El diseño del cuestionario se realizó a partir de los ejes problemáticos detectados con anterioridad.
        li
          p
            | Las entrevistas fueron realizadas de forma semi-estructurada, ciñéndose a las temáticas identificadas previamente.
        li
          p
            | Para el trabajo se siguieron recomendaciones y buenas prácticas para las entrevistas a expertos (Bogner et al., 2009), destacando el tratamiento de los resultados a partir de poner en valor el conocimiento de los entrevistados de los procesos y asuntos públicos a estudiar (Bogner y Menz, 2009); y atendiendo las recomendaciones para entrevistas telefónicas o mediadas por computadora (Christmann, 2009).
      p
        | Para la 
        strong triangulación
        |  de resultados se siguieron los siguientes lineamientos:
      ul
        li
          p
            | Reuniones periódicas para cotejar avances, explorar complementariedades e informar áreas de oportunidad para la indagación.
        li
          p
            | Presentaciones de avances y reuniones de trabajo con el equipo de Tecnologías para el Cambio para validar hallazgos y acordar posibles vías de avance en la investigación.
        li
          p
            | Se realizó la incorporación, a partir de estos loops y feedbacks sobre los resultados, de nuevas perspectivas para el análisis de datos, el guión de entrevistas y la interpretación de resultados.
</template>
<script>

export default {
  name:"Chapter2",
}
</script>
