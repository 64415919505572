<template lang="pug">
  #cap-6.section
    // Capítulo 6 (Portada)
    #banner-6.banner
      .container-fluid
        .row
          .col-12.col-md-7.mx-0.px-0
            img.banner-img(src="/assets/Portada Cap 4 copy.jpg")
          .banner-col.col-12.col-md-5.d-flex.justify-content-center.align-items-center
            
            h5.banner-title.mt-1.pb-2
              span.conclusiones Conclusiones
              div.mt-4 ¿Cómo incidir positivamente en las vidas de
              div.boldest quienes trabajan en la industria indumentaria?
    //- #banner-6.banner
    //-   h2
    //-     span.conclusiones Conclusiones:
    //-   h2.banner-title
    //-     | ¿Cómo incidir positivamente en las vidas de 
    //-     span quienes trabajan en la industria indumentaria?
    // Capítulo 6
    #cap-6sub1.cap.subsection-cap
      h3.mb-4
        span.subsection  Principales hallazgos
      ul
        li
          p
            | La 
            strong industria
            |  de la indumentaria 
            strong genera gran cantidad de empleos
            | . La 
            strong calidad
            |  de estos 
            strong puestos de trabajo
            |  es menor que la media de la industria manufacturera.
        li
          p
            | Existe una 
            strong brecha de género
            |  en las dinámicas laborales del sector. Esta es visible en niveles salariales más bajos, junto con la ocupación de mujeres en puestos con menores niveles de tecnificación, de responsabilidad y, por ende, de remuneración. Las labores de cuidado que deben afrontar las mujeres intervienen fuertemente en su actividad laboral, afectando el nivel de ingresos efectivos percibidos. Es decir, no solo las mujeres ganan menos que los hombres, hacen otro tipo de trabajos y suman las obligaciones de cuidado a las responsabilidades laborales.
        li
          p
            | El 
            strong perfil laboral
            |  que se ve es el de mujeres de mediana edad, con educación formal incompleta y responsables de labores de cuidado en sus hogares.
        li
          p
            | Existen 
            strong formas emergentes
            |  de 
            strong tercerización
            |  que diluyen la responsabilidad de las empresas que realizan las órdenes de producción. La más destacada es el trabajo desde casa o ‘home-working’. Las condiciones laborales son sustancialmente peores en estos espacios, pero brindan flexibilidad a las mujeres que tienen otras obligaciones como estar a cargo de labores de cuidado en sus hogares.
        li
          p
            | La 
            strong brecha
            |  de 
            strong acceso a prestaciones
            |  es, al menos, dos veces mayor para las mujeres que para los hombres. Los beneficios -fondos de retiro, créditos para vivienda, reparto de utilidades, vacaciones pagadas, aguinaldo- en la industria son bajos y son más bajos para las mujeres.
        li
          p
            | La 
            strong sindicalización
            |  es menor en esta rama de la industria y cumple sus objetivos de forma incompleta. Los sindicatos independientes son una figura extraña en las formas de representación.
        li
          p
            | Los 
            strong colectivos
            |  y las 
            strong organizaciones
            |  civiles son claves en las luchas por los derechos laborales. Sin embargo, su espacio de incidencia es a nivel individual, es decir, atendiendo caso por caso.
        li
          p
            strong Norte
            | . Las industrias son un poco más grandes que en otras áreas del país. La organización sindical es más fuerte. Los cambios en su estructura productiva están insertando las labores de confección en otras cadenas de valor, sobre todo automotriz y aeroespacial.
        li
          p
            strong Centro
            | . Las empresas tienden a ser pequeñas. Las empresas funcionan articulando entre sí la producción. Se informan redes de confianza y co-propiedad que sostienen estas prácticas. Hay casos resonantes de intentos fallidos de organización sindical independiente que se han dado, sobre todo, en plantas grandes.
        li
          p
            strong Sur
            | . El sector ha crecido mucho durante los últimos años. El 95% de estos establecimientos son microempresas. El desarrollo de figuras de subcontratación e informalidad, como el trabajo en el hogar, plantean desafíos a la organización de las trabajadoras.
        li
          p
            strong Bajío
            | . Tiene otro perfil productivo -calzado y marroquinería- que ha diversificado su giro. El crecimiento de otras industrias con mejores condiciones laborales ha disputado la mano de obra a la industria de la indumentaria.
        li
          p
            | Las 
            strong empresas
            |  más 
            strong grandes
            |  favorecen la 
            strong contratación formal
            | . Sin embargo, los sistemas de remuneración variable inciden negativamente en la salud de las trabajadoras y las hacen vulnerables a los ciclos productivos del sector.
        li
          p
            | Desde un punto de vista cuantitativo se ha visto que el 
            strong modelo productivo
            |  -incentivos de producción, pago a destajo, interiorización de exigencia- no puede separarse de la incidencia en el bienestar y la salud de las trabajadoras.
        li
          p
            strong COVID y condiciones estructurales
            | . Hemos descrito las condiciones estructurales de la industria, frente al COVID, estas condiciones se profundizan. Afianza y acrecienta un perfil de abusos. Las limitaciones económicas limitan la voluntad de reclamar.
    #cap-6sub2.cap.subsection-cap 
      h3.mb-4
        span.subsection Áreas y estrategias de incidencia potencial
      ul
        li
          p
            | Necesitamos aprender más sobre la 
            strong discriminación
            |  en las empresas. Para entender qué tan recurrente es el perfilamiento y las prácticas de contratación en las empresas necesitamos tener datos que permitan ligar con las distintas industrias.
        li
          p
            | Es un desafío poder probar, con 
            strong datos
            | , la relación e intersección que tienen las prácticas laborales con las brechas de género, la precariedad laboral y los padecimientos de salud. Tenemos descripciones cualitativas del proceso, pero hace falta ver cómo se estructura y distribuye en distintos contextos.
        li
          p
            | La falta de datos con desagregación a 
            strong nivel municipal
            |  limita el acceso y la descripción desagregada de los fenómenos. Esto es clave para poder generar procesos de rendición de cuentas focalizados sobre los territorios, sus empresas y sus organismos de control.
        li
          p
            | El desarrollo de datos que valoricen y cuantifiquen las labores de cuidado es un área de incidencia clave. Estos son sensibles y estratégicos para distintos 
            em stakeholders
            |  del sector, como actores públicos, privados y organizaciones de la sociedad civil.
        li
          p
            | La 
            strong complejidad del sector
            |  tiende a incrementarse. Esto ensancha las brechas observadas en términos de ingreso, calidad del empleo, libertad de organización, y equidad de género. El desafío es 
            strong generar formas flexibles
            |  de incidencia que tengan en cuenta estas características.
        li
          p
            | El desafío es 
            strong promover formas organizativas dinámicas y de base territorial
            | . Deben poder articular la diversidad en capacidades e intereses, pero también en la edad y las generaciones de sus miembros. Asimismo, la posibilidad de articular redes y comunidades de práctica es clave para generar una base de confianza mutua que permita compartir herramientas y experiencias que potencien la incidencia.
        li
          p
            | Generar 
            strong dinámicas de apropiación
            |  de los datos disponibles a partir del uso de la plataforma interactiva desarrollada ad-hoc sobre el tema. Esta es una herramienta clave para disparar la conversación sobre el tema, plantear nuevas preguntas y señalar áreas emergentes de incidencia en las que existen limitaciones en los datos disponibles.
      h2#listado-de-organizaciones-entrevistadas Listado de organizaciones entrevistadas
      ul.listado-orgs
        li
          | ARLENICA
        li
          | Centro de Apoyo a la Mujer en las Maquilas de la Laguna, A.C.
        li
          | Clínica Jurídica - Clínica de Acción Legal (PUDH - UNAM)
        li
          | Colectivo de trabajadoras de la maquila y del hogar de Torreón
        li
          | Colectivo Juventud entre Tules A.C.
        li
          | Colectivo RAIZ
        li
          | Colegio de Sonora
        li
          | Comité Fronterizo de Obrerxs - CFO
        li
          | Fábrica Social - Básicos de México
        li
          | Fondo Semillas
        li
          | Fray Francisco de Vitoria, Centro de Derechos Humanos
        li
          | Hispanics in Philanthropy
        li
          | Maquila Solidarity Network
        li
          | MexMode
        li
          | Proyecto de derechos Económicos Sociales y Culturales A.C.
        li
          | Yo Quiero, Yo Puedo (YQYP)
  


</template>
<script>
import Sidenav from "@/components/Sidenav.vue";
export default {
  name:"Chapter6",
  components: {
    Sidenav
  }
}
</script>