<template lang="pug">
  #cap-7.section
    #cap-7sub1.subsection-cap
      section#referencias
          h2(id='bibliografía') Bibliografía
          p
            | Bogner, A., Littig, B., y Menz, W. (2009). Introduction: Expert Interviews — An In- troduction to a New Methodological Debate. En A. Bogner, B. Littig, y W. Menz (Eds.), Interviewing Experts (pp. 1–13). London: Palgrave Macmillan UK. Descargado 2020-06-24, de &nbsp;
            a.uri(href='http://link.springer.com/10.1057/9780230244276_1') http://link.springer.com/10
            |  doi: 10.1057/ 9780230244276_1
          p
            | Bogner, A., y Menz, W. (2009). The Theory-Generating Expert Interview: Epistemological Interest, Forms of Knowledge, Interaction. En A. Bogner, B. Littig, y W. Menz (Eds.), Interviewing Experts (pp. 43–80). London: Palgrave Macmillan UK. Descargado 2020-06- 25, de &nbsp; 
            a.uri(href='https://doi.org/10.1057/9780230244276_3') https://doi.org/10.1057/9780230244276_3
            |  doi: 10.1057/9780230244276_3
          p
            | Christmann, G. B. (2009). Expert Interviews on the Telephone: A Difficult Undertaking. En A. Bogner, B. Littig, y W. Menz (Eds.), Interviewing Experts (pp. 157–183). Lon- don: Palgrave Macmillan UK. Descargado 2020-06-26, de &nbsp; 
            a.uri(href='https://doi.org/10.1057/') https://doi.org/10.1057/
            |  9780230244276_8 doi: 10.1057/9780230244276_8
          p
            | Denman, C. A. (1990). La salud de las obreras de la maquila: El caso de Nogales, Sonora. 229-252. Elson, D., & Pearson, R. (1981). Nimble Fingers Make Cheap Workers: An Analysis of Women’s Employmentt in Third world Export Manufacturing. Feminist Review.
          p
            | Eskenazi, B., Guendelman, S., & Elkin, E. P. (1993). A Preliminary Study of Reproductive Outcomes of Female Maquiladora Workers in Tijuana, Mexico. American Journal of Industrial Medicine, 667-676.
          p
            | Guendelman, S., & Silberg, J. (1993). The Health Consequences of Maquiladora Work: Women on the US-Mexican Border. American Journal of Public Health , 44.
          p
            | Hispanics in Philantropy. (2018). Avanzando el bienestar de trabajadoras en el sector moda en México. Ciudad de México.
          p
            | Hispanics in Philantropy; Fundación C&A. (s.f.). Logros y retos de la responsabilidad social corporativa en el sector de la moda en México. Ciudad de México.
          p
            | Instituto Nacional de Estadística y Geografía (INEGI). (6 de 2020). Estadísticas a propósito de… la industria de la moda del vestigo, el calzado y la joyería. Obtenido de  &nbsp;
            a.uri(href='https://books.google.com.mx/books?id=dSPZDwAAQBAJ&printsec=frontcover&source=gbs_ge_summary_r&cad=0#v=onepage&q&f=false')
              | https://books.google.com.mx/books
          p
            | Kurczyn Villalobos, P. (2002). Derechos de las mujeres trabajadoras. Ciudad de México: UNAM.
          p
            | Mills, M. (1995). Gender and Inequality in the Global Labor Force. Annual Review of Anthropology, 41-62.
          p
            | MIT CCM, & Berkman Klein Center. (2020). Media Cloud (v3.13.20) [Computer software]. MIT Center for Civic Media and Berkman Clein Center for Internet and Society at Harvard University. 
            a.uri(href='https://mediacloud.org') https://mediacloud.org
          p
            | Minián, I., Martínez, Á., & Ibañez, J. (2017). Cambio tecnológico y relocalización de la industria del vestido. Problemas del desarrollo.
          p
            | Paul-Majumder, P. (1996). Health Impact of Women’s Wage Employment: A Case Study of the Garment Industry of Bangladesh. The Bangladesh Development Studies, 59-102.
          p
            | SciencePo MédiaLab. (2020). Hyphe: A web corpus curation tool featuring a research-driven web crawler. (1.0) [Herramienta informática]. 
            a.uri(href='http://hyphe.medialab.sciences-po.fr/') http://hyphe.medialab.sciences-po.fr/
          p
            | Safa, H. (1995). The Myth of the Male Breadwinner: Women and Industrialization in the Caribbean. Westview Press.
          p
            | Stichting Onderzoek Multinationale Ondernemingen. (2011). Aspectos de género en la industria indumentaria latinoamericana. Amsterdam.
          | Villegas, J. N. (1997). Trabajo y salud en la industria maquiladora mexicana: una tendencia dominante en el neoliberalismo dominado. Cad. Saúde Pública.
      //- .footnotes
      //-   hr
      //-   #fn1
      //-     p
      //-       | 1. Según la ENOE, la población ocupada la conforman personas de 15 y más años de edad que en la semana de referencia realizaron alguna actividad económica durante al menos una hora. Incluye a los ocupados que tenían trabajo, pero no lo desempeñaron temporalmente por alguna razón, sin que por ello perdieran el vínculo laboral con este; así como a quienes ayudaron en alguna actividad económica sin recibir un sueldo o salario.
      //-       a.footnote-back(href='#fnref1') ↩︎
      //-   #fn2
      //-     p
      //-       | 2. Dividimos el país en cinco regiones, dentro de la región Norte del país están Baja California, Baja California Sur, Coahuila, Chihuahua, Nuevo León, Sinaloa, Sonora y Tamaulipas. En la región Bajío están Aguascalientes, Colima, Durango, Guanajuato, Jalisco, Michoacán, Nayarit, Querétaro, San Luis Potosí y Zacatecas. En la región Centro están la Ciudad de México, Hidalgo, el Estado de México, Morelos Puebla y Tlaxcala. Finalmente, en la región Sur están Campeche, Chiapas, Guerrero, Oaxaca, Quintana Roo, Tabasco, Veracruz y Yucatán.
      //-       a.footnote-back(href='#fnref2') ↩︎

</template>
<script>
import Sidenav from "@/components/Sidenav.vue";
export default {
  name:"Chapter6",
  components: {
    Sidenav
  }
}
</script>