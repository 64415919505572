<template lang="pug">
#sidemenu
  nav#desktopnav.nav.navbar.desktop.py-3.px-0
    nav.nav.flex-column.main(v-for="(section, index) in sections")
      a.title.nav-link.bolder.my-1.px-3( :class="'cap-' + (index + 1)" :href="'#cap-'+ (index + 1)" @click="activeLink(index)") {{section.title}}
      nav.nav.flex-column.sub
        a.sub.subtitle.smaller.bold.my-1.px-3(:class="'cap-' + (index + 1) + 'sub' + (subindex + 1)" v-for="(subsection, subindex) in section.subsections" :href="'#cap-' + section.number + 'sub'+ subsection.number") {{subsection.title}}
  
  b-navbar#mobilenav.mobile( toggleable="sm" v-b-scrollspy:scrollspy-nested.200)
    b-navbar-toggle.ml-auto( data-toggle="collapse" target="nav-text-collapse")
    b-collapse(id="nav-text-collapse")
      b-navbar-nav.main(v-for='(section, index) in sections')
        b-nav-item.bolder(:href="'#cap-'+ (index + 1)") {{section.title}}
        //- b-navbar-nav.sub(v-for='(subsection, subindex) in section.subsections')
        //-   b-nav-item.sub.smaller.bold.my-1.px-3(:href="'#cap-' + section.number + 'sub'+ subsection.number" :class="subsection.id") {{subsection.title}}



  
  

</template>
<script>
export default {
  name: "Sidenav",
  data() {
    return {
      sections: [
        {
          number: 1,
          title: "INTRODUCCIÓN",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "Objetivo del Proyecto",
              active: false,
            },
          ],
        },
        {
          number: 2,
          title: "METODOLOGÍA",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "",
              active: false,
            },
          ],
        },
        {
          number: 3,
          title: "1. Las condiciones laborales de las trabajadoras",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title:
                "Precariedad laboral generalizada, pero aún más profunda para las mujeres",
              active: false,
            },
            {
              number: 2,
              title:
                "La especialización por región y género explica en parte la desigualdad",
              active: false,
            },
          ],
        },
        {
          number: 4,
          title: "2. La industria indumentaria vs. salud, pobreza y violencia",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "",
              active: false,
            },
          ],
        },
        {
          number: 5,
          title: "3. COVID: La pandemia como desafío emergente",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "",
              active: false,
            },
          ],
        },
        {
          number: 6,
          title:
            "Conclusiones: ¿Cómo incidir positivamente en las vidas de quienes trabajan en la industria indumentaria?",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "Principales hallazgos",
              active: false,
            },
            {
              number: 2,
              title: "Áreas y estrategias de incidencia potencial",
              active: false,
            },
          ],
        },
        {
          number: 7,
          title: "BIBLIOGRAFÍA",
          showCollapse: false,
          subsections: [
            {
              number: 1,
              title: "",
              active: false,
            },
          ],
        },
      ],
    };
  },
  methods: {
    activeLink(chosen) {
      const sections = document.querySelectorAll(".title.nav-link");
      // console.log(sections)
      sections.forEach((s, i) => {
        if (i == chosen) {
          s.classList.add("active");
          // console.log(s.querySelectorAll('a.sub'))
          // s.querySelectorAll('a.sub').style.display = "block";
          s.querySelectorAll("a.sub").forEach((child) => {
            child.style.display = "block";
          });
        } else {
          s.classList.remove("active");
          s.querySelectorAll("a.sub").forEach((child) => {
            child.style.display = "none";
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
#sidemenu {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 1.5rem;
  // margin-top: 4rem;
  color: #e8f1f9;
  #desktopnav {
    background-color: #d1e2f3;
  }
  #mobilenav {
    background-color: #fff;
    top: 0;
  }
  .main {
    width: 100%;
    border-bottom: 1px solid #3e7ba9;
  }
  .nav-link.active {
    background-color: #bed5ed;
  }
  .subtitle {
    display: none;
  }
  .subtitle.active {
    background-color: #bed5ed;
  }
}
</style>
