<template lang="pug">
#cap-4.section
  // Capítulo 4 (Portada)
  #banner-4.banner
      .container-fluid
        .row
          .col-12.col-md-7.mx-0.px-0
            img.banner-img(src="/assets/Portada Cap 2 copy.jpg")
          .banner-col.col-12.col-md-5.d-flex.justify-content-center.align-items-center
            h5.banner-title.mt-3.pb-2
              | Las industria indumentaria vs.
              div.boldest salud, pobreza y violencia
  //- #banner-4.banner
  //-   h2.banner-title
  //-     | La industria indumentaria vs.
  //-     span salud, pobreza y violencia
  //-         // Capítulo 4
  #cap-4sub1.subsection-cap.cap
    h3.mb-4
      span.subsection La industria indumentaria vs. salud, pobreza y violencia
    p
      | Al inicio de este proyecto de investigación, nos propusimos explorar la relación entre la presencia de la industria indumentaria y otros temas como salud pública, pobreza y violencia. El acercamiento a estos temas desde lo cuantitativo fue complicado debido a que los datos de industria indumentaria a nivel municipal son muy limitados, pero echando mano del trabajo cualitativo y de los pocos datos disponibles pudimos explorar la relación entre estas variables.
    p
      | En primer lugar, exploramos la relación de la incidencia delictiva y la violencia con la industria. dentro de las variables que consideramos para medir violencia están las tasas de carpetas de investigación abiertas por lesiones, homicidio, secuestro y violación, así como las tasas de homicidios de hombres y mujeres. No encontramos mucho en todas las variables, pero encontramos una relación entre la tasa de lesiones de una ciudad y la presencia de la industria.
    p
      | Desde lo cuantitativo no pudimos encontrar mucha evidencia, pero este tema sí aparece dentro de los hallazgos cualitativos, especialmente por dos cuestiones informadas en las entrevistas. Primero, en relación a la ubicación de las fábricas en zonas periurbanas y las formas de desplazamiento que muchas veces incluyen vehículos propios como motos o bicicletas.
    blockquote
      p.quote-body
        | Pero los demás hasta traen su bicicleta, su moto. Yo he visto allí un chorro de bicicletas y motos que van. Pero no hay seguridad, no tienen seguridad porque los camiones están inservibles; son unos amarillos que tienen allá desechados en los Estados Unidos, no sé si los habrás visto.
      p.quote-footer
        | — ENTREVISTA 03; Activista DHL, Mujer, Coahuila
    p
      | En segundo lugar, la violencia tiene un impacto muy importante en las dinámicas de organización de las trabajadoras lo que puede afectar sus posibilidades de obtener mejores condiciones laborales. En casos extremos, ha impactado directamente en los procesos que llevan adelante los colectivos.
    blockquote
      p.quote-body
        | Aquí fue una área de mucha violencia, en 2012, 2010, que teníamos mucha gente en el colectivo y que estábamos impartiendo regularmente talleres cada mes. Entonces debido a la inseguridad, porque sí fue mucha, fue mucha violencia. Entonces, pues teníamos miedo de ser localizados o que nuestras compañeras al ir a visitarnos tuvieran algún conflicto. A raíz de eso el colectivo se dispersa y, pues, teníamos contacto con algunas trabajadoras pero pues para darles orientación y que ellos más adelante la siguieran pasando a las trabajadoras.
      p.quote-footer
        | —ENTREVISTA 12; Activista DHL, Mujer, Coahuila
    p
      | En tercer lugar, quisimos explorar la relación entre la presencia de la industria indumentaria con algunas variables de salud. Desde el análisis cuantitativo, exploramos cómo es la tasa de natalidad en las ciudades con más y menos industria indumentaria. En este caso encontramos que la tasa de natalidad sí es más alta en lugares con poca industria indumentaria. No obstante, de nuevo, eso puede deberse a las características de las ciudades con más industria indumentaria, en vez de a su presencia.
    img.responsive(src="assets/Graf_8.jpg")
    p
      | Dentro del mismo tema de la salud, exploramos la relación entre la tasa de ingresos al hospital por afecciones respiratorias y la presencia de la industria indumentaria en la ciudad. En este sentido nos percatamos de que en el centro y bajío del país entre más población trabajaba en la industria, mayor era la tasa de ingresos hospitalarios. Esta relación no se mantiene en todas las regiones del país; en el sur la relación casi no existe y en el norte parece ser inversa.
    p
      | Si bien esta relación no es suficiente para probar causalidad, sí concuerda con otros hallazgos del análisis cualitativo. Lo que nos lleva a pensar que con mejores datos podríamos corroborar estos hallazgos, según los cuales, la salud laboral es un área clave para entender los impactos del modelo productivo. La exigencia a la que se ven sometidas es clave para explicar padecimientos de salud corrientes entre las obreras. Estos son, sobre todo, problemas de riñones, respiratorios y lesiones en tendones y articulaciones.
    blockquote
      p.quote-body
        | (…) hay quienes, por ejemplo, no quieren ausentarse de la línea de producción porque eso les baja o les disminuye la producción, lo cual tiene su defecto en que, si les pagan por destajo, el hecho de ir al baño les quita 10 o 5 minutos, por lo que prefieren no ir o no tomar agua. Es como una cadena de consecuencias que tiene la forma en que ellos perciben estas medidas de seguridad o auto-cuidado. También está la idea de que, si tienen media hora para comer, prefieren usar 10 o 15 minutos para regresar y poder tener una producción más alta para que eso se vea reflejado también en su pago. Es un conjunto de cosas de cómo ellos perciben el auto-cuidado, el cual está relacionado con estas medidas de seguridad e higiene.
      p.quote-footer
        | —ENTREVISTA 05; Activista DHL, Mujer, CDMX
    p
      | En cuarto y último lugar, exploramos cómo son las ciudades con mayor industria indumentaria en términos de pobreza. En este tema es muy consistente que aquellas ciudades con mayor presencia de la industria indumentaria tienen un mayor porcentaje de población en pobreza, pobreza extrema y moderada.
    p
      | El hecho de que estas ciudades tengan porcentajes muy altos de personas en pobreza, explica en parte por qué las trabajadoras se mantienen en estos trabajos con condiciones laborales precarias. Como ya hemos visto, el resultado del trabajo cualitativo ha informado sobre el tipo de perfil que suele ser el denominador común en las contrataciones de la industria: mujeres jóvenes, sin educación y, generalmente, jefas de familia.
    blockquote
      p.quote-body
        | Pero sabes por qué están ahí las trabajadoras, por necesidad. Por necesidad, porque muchas son madres solteras y tienen tres o cuatro hijos. Tienen que mantenerlos, o no completa el matrimonio con lo que están ganando tienen que trabajar las dos partes. Entonces, digo yo, yo las veo y digo ‘gracias a Dios yo no tengo ninguna necesidad porque, bueno, yo sé hacer otras cosas’. Pero digo, estoy ahí y digo ‘cómo me doy cuenta de todo lo que pasa adentro’.
      p.quote-footer
        | — ENTREVISTA 03; Activista DHL, Mujer, Coahuila
    p
      | Sobre esto también decir que algunas autoras estudiosas del tema explican que si bien la industria ofrece trabajos precarios con condiciones laborales subóptimas, en algunas regiones del país estas condiciones siguen siendo mejores que lo que ofrecen la mayoría de los trabajos.
    p
      | En esta sección del reporte pudimos hacer una análisis de la relación que tiene la industria indumentaria con otros temas que se mencionan frecuentemente dentro de la literatura. Hay hallazgos importantes sobre la relación de la industria con violencia, pobreza y salud. No obstante, este análisis es aún muy limitado, dada la falta de información disponible.
    p
      | Para hacer un análisis serio que permitiera establecer relaciones y causalidades claras entre la presencia de la industria en una comunidad y las condiciones de salud, pobreza o violencia que viven sus habitantes haría falta mayor cantidad de información a nivel granular. Mientras esta información no esté disponible será muy complicado contestar todas las preguntas pendientes sobre el efecto de la industria indumentaria en las comunidades que ocupa.
            
</template>
<script>
export default {
  name:"Chapter4",
}
</script>
