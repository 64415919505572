<template lang="pug">
#Heatmaps
  .d-flex.my-4
    #chart1
    #chart2(v-bind:style="selectedSex.length >=2 ? border : ''")
  .d-flex.justify-content-center.legend-container
    #legend
</template>
<script>
import * as d3 from "d3";

export default {
  name: "Heatmaps",
  data() {
    return {
      border: "border-left: solid 2px #b9babb;",
      config: {
        margin: {
          top: 5,
          right: 35,
          bottom: 35,
          left: 200
        },
        size: {
          width: 100,
          height: 400
        },
        legend: {
          width: 100,
          padding: 10
        }
      },
      bindTo1: "chart1",
      bindTo2: "chart2",
      chart1: {},
      chart2: {}
    };
  },
  computed: {
    dataSet() {
      return this.$store.state.filteredDataSet;
    },
    selectedSex() {
      return this.$store.state.filters.selectedSex;
    },
    selectedLabourConditions() {
      return this.$store.state.filters.selectedLabourConditions;
    }
  },
  watch: {
    dataSet() {
      this.setChart();
    }
  },
  methods: {
    setChart() {
      if (d3.select("#Heatmaps").node()) {
        this.config.size.width =
          (d3
            .select("#Heatmaps")
            .node()
            .getBoundingClientRect().width -
            this.config.margin.left -
            this.config.margin.right) /
          this.selectedSex.length;
      }

      this.config.size.height =
        550 - this.config.margin.top - this.config.margin.bottom;
      const width = this.config.size.width,
        height = this.config.size.height,
        margin = this.config.margin;

      this.config.legend.width = d3
        .select("#Heatmaps")
        .node()
        .getBoundingClientRect().width;

      d3.select("#" + this.bindTo1 + " svg").remove();
      d3.select("#" + this.bindTo2 + " svg").remove();
      d3.select("#legend svg").remove();

      const svg1 = d3
        .select("#" + this.bindTo1)
        .append("svg")
        .attr("width", width + margin.left)
        .attr("height", height + margin.bottom + margin.top);

      const g1 = svg1
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      this.chart1["svg"] = g1;
      if (this.selectedSex.length > 1) {
        const svg2 = d3
          .select("#" + this.bindTo2)
          .append("svg")
          .attr("width", width + margin.right)
          .attr("height", height + margin.bottom + margin.top);
        const g2 = svg2
          .append("g")
          .attr("transform", `translate(${margin.right},${margin.top})`);
        this.chart2["svg"] = g2;
      }
      this.drawHeatmaps();
    },
    drawHeatmaps() {
      let data1;
      let data2;

      if (this.selectedSex.length > 1) {
        data1 = this.dataSet.filter(d => d.sex == this.selectedSex[0]);
        data2 = this.dataSet.filter(d => d.sex == this.selectedSex[1]);
      } else if (this.selectedSex.length == 1) {
        data1 = this.dataSet;
      } else {
        data1 = this.dataSet;
      }
      const groups = Object.keys(this.dataSet[0]).slice(2);
      const labour = this.selectedLabourConditions;

      data1 = data1.reduce((list, currentGroup) => {
        groups.forEach(group => {
          const newItem = {
            labour: currentGroup.labour,
            group: group,
            value: currentGroup[group],
            sex: currentGroup.sex
          };
          list.push(newItem);
        });
        return list;
      }, []);

      if (this.selectedSex.length > 1) {
        data2 = data2.reduce((list, currentGroup) => {
          groups.forEach(group => {
            const newItem = {
              labour: currentGroup.labour,
              group: group,
              value: currentGroup[group],
              sex: currentGroup.sex
            };
            list.push(newItem);
          });
          return list;
        }, []);
      }
      const minMaxArray = data1
        .concat(data2)
        .filter(d => d !== undefined && d.value !== "NA");
      const maxVal = Math.max(...minMaxArray.map(({ value }) => value));
      const minVal = Math.min(...minMaxArray.map(({ value }) => value));

      let x = d3
        .scaleBand()
        .range([0, this.config.size.width - this.config.margin.right])
        .padding(0.02);
      x.domain(groups);

      let y = d3
        .scaleBand()
        .range([this.config.size.height - 20, this.config.margin.right])
        .padding(0.02);
      y.domain(labour);

      let color = d3
        .scaleLinear()
        .range(["#fff", "#f03089"])
        .domain([0, maxVal]);

      this.chart1.svg
        .append("g")
        .attr("class", "xaxis-heat")
        .attr(
          "transform",
          "translate(0," + (this.config.size.height - 20) + ")"
        )
        .call(d3.axisBottom(x).tickSize(0))
        .select(".domain")
        .remove();

      this.chart1.svg
        .append("g")
        .attr("class", "yaxis")
        .attr("transform", `translate(-20,0)`)
        .call(d3.axisLeft(y).tickSize(0))
        .select(".domain")
        .remove();

      this.chart1.svg
        .selectAll(".yaxis .tick text")
        .call(wrap, this.config.margin.left - 15);
      this.chart1.svg
        .selectAll(".xaxis-heat .tick text")
        .call(wrap, x.bandwidth());

      this.chart1.svg
        .selectAll()
        .data(data1, d => d.group + ":" + d.labour)
        .enter()
        .append("rect")
        .attr("class", "unit")
        .attr("x", d => x(d.group))
        .attr("y", d => y(d.labour))
        .attr("width", x.bandwidth())
        .attr("height", y.bandwidth())
        .style("fill", d => (d.value == "NA" ? "#DEDEDE" : color(d.value)))
        .style("stroke", "none");

      this.chart1.svg
        .selectAll("text")
        .data(data1, d => d.group + ":" + d.labour)
        .enter()
        .append("text")
        .attr("class", "heatmap-labels")
        .attr("x", d => x(d.group) + x.bandwidth() / 2 - 9)
        .attr("y", d => y(d.labour) + (y.bandwidth() / 2 + 5))
        .text(d => (d.value == "NA" ? "ND" : Math.round(d.value) + "%"))
        .attr("fill", d => (d.value > maxVal / 2 ? "#fff" : "#6d6e71"));

      this.chart1.svg
        .append("text")
        .attr("class", "chart-title")
        .attr("x", (this.config.size.width - this.config.margin.right) / 2)
        .attr("y", 8)
        .text(data1[0].sex.toUpperCase())
        .style("text-anchor", "middle");

      if (this.selectedSex.length > 1) {
        this.chart2.svg
          .append("g")
          .attr("class", "xaxis-heat")
          .attr(
            "transform",
            "translate(0," + (this.config.size.height - 20) + ")"
          )
          .call(d3.axisBottom(x).tickSize(0))
          .select(".domain")
          .remove();

        this.chart2.svg
          .selectAll(".xaxis-heat .tick text")
          .call(wrap, x.bandwidth());

        this.chart2.svg
          .selectAll()
          .data(data2, d => d.group + ":" + d.labour)
          .enter()
          .append("rect")
          .attr("class", "unit")
          .attr("x", d => x(d.group))
          .attr("y", d => y(d.labour))
          .attr("width", x.bandwidth())
          .attr("height", y.bandwidth())
          .style("fill", d => (d.value == "NA" ? "#DEDEDE" : color(d.value)))
          .style("stroke", "none");

        this.chart2.svg
          .selectAll("text")
          .data(data2, d => d.group + ":" + d.labour)
          .enter()
          .append("text")
          .attr("class", "heatmap-labels")
          .attr("x", d => x(d.group) + x.bandwidth() / 2 - 9)
          .attr("y", d => y(d.labour) + (y.bandwidth() / 2 + 5))
          .text(d => (d.value == "NA" ? "ND" : Math.round(d.value) + "%"))
          .attr("fill", d => (d.value > maxVal / 2 ? "#fff" : "#6d6e71"));

        this.chart2.svg
          .append("text")
          .attr("class", "chart-title")
          .attr("x", (this.config.size.width - this.config.margin.right) / 2)
          .attr("y", 8)
          .text(data2[0].sex.toUpperCase())
          .style("text-anchor", "middle");
      }
      //add legend
      const minColor = "#fff",
        maxColor = "#f03089";
      const legendX = 200;
      const legendY = 80;

      const legendContainer = d3
        .select("#legend")
        .append("svg")
        .attr("width", legendX)
        .attr("height", legendY)
        .append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")");

      const defs = legendContainer.append("defs");
      const linearGradient = defs
        .append("linearGradient")
        .attr("id", "linear-gradient");

      linearGradient
        .attr("x1", "0%")
        .attr("y1", "0%")
        .attr("x2", "100%")
        .attr("y2", "0%");

      linearGradient
        .append("stop")
        .attr("offset", "0%")
        .attr("stop-color", minColor);
      linearGradient
        .append("stop")
        .attr("offset", "100%")
        .attr("stop-color", maxColor);

      const legend = legendContainer.attr("class", "legend");
      legend
        .append("rect")
        .attr("x", 0)
        .attr("y", 14)
        .attr("width", legendX - 10)
        .attr("height", 25)
        .style("fill", "url(#linear-gradient)");

      legend
        .append("text")
        .attr("class", "legend-text")
        .attr("x", 0)
        .attr("y", 10)
        .text("Porcentaje (%)");

      const scaleTicks = d3
        .scaleLinear()
        .domain([minVal, maxVal])
        .range([0, 190]);
      const axisLegend = d3
        .axisBottom(scaleTicks)
        .tickSize(0)
        .ticks(5);
      legend
        .attr("class", "legend-text")
        .append("g")
        .attr("transform", "translate(" + 3 + "," + 40 + ")")
        .call(axisLegend)
        .call(g => g.select(".domain").remove());

      function wrap(text, width) {
        text.each(function() {
          var text = d3.select(this),
            words = text
              .text()
              .split(/\s+/)
              .reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", `${++lineNumber * lineHeight + dy}em`)
                .text(word);
            }
          }
        });
      }
    }
  },
  mounted() {
    this.setChart();
  }
};
</script>
<style lang="scss">
#Heatmaps {
  .heatmap-labels {
    font-size: 13px;
    font-weight: bold;
  }
  .legend-container {
    margin-left: 165px;
  }
}
</style>
