<template lang="pug">
#Charts
  b-container
    b-row.pt-3(align-h="center")
      b-col(sm="9")
        h2.title.text-center.boldest.uppercase.grey-color.pb-2 {{ titleText }}
    b-row(align-h="center")
      b-col(cols="12")
        Bars(v-if="chartType == 'bar'")
        Heatmaps(v-if="chartType == 'heatmap'")
        HexMap(v-if="chartType == 'map'")
        p.source {{ sourceText }}
</template>

<script>
import Bars from "@/components/Bars.vue";
import Heatmaps from "@/components/Heatmaps.vue";
import HexMap from "@/components/HexMap.vue";
export default {
  name: "Charts",
  components: {
    Bars,
    Heatmaps,
    HexMap
  },
  data() {
    return {};
  },
  computed: {
    selectedEconomicUnit() {
      return this.$store.state.filters.selectedEconomicUnit;
    },
    chartType() {
      return this.$store.state.chartType;
    },
    sourceText() {
      return this.chartType == "map"
        ? "Fuente: Directorio Estadístico Nacional de Unidades Económicas consultado en Junio de 2020"
        : "Fuente: ETOE 1er trimestre de 2020";
    },
    titleText() {
      let title = "";
      if (this.chartType !== "map") {
        title =
          "¿Qué condiciones laborales tienen las personas que trabajan en la industria indumentaria en México?";
      } else if (this.chartType == "map") {
        switch (this.selectedEconomicUnit) {
          case "ii":
            title = "¿Dónde se concentra la industria indumentaria en México?";
            break;
          case "im":
            title =
              "¿Cuántas empresas de la entidad se dedican a la industria indumentaria?";
            break;
          case "jobs":
            title =
              "¿Cuántos trabajos en la entidad dependen de la industria indumentaria?";
            break;
          case "tot":
            title =
              "¿Cuántas empresas manufactureras de la entidad se dedican a la industria indumentaria?";
            break;
          default:
            title = "¿Dónde está la industria indumentaria en México?";
        }
      }
      return title;
    }
  }
};
</script>
<style lang="scss">
#Charts {
  .title {
    border-bottom: solid 1.5px #f94098;
    font-size: 1.3rem;
  }
  .source {
    color: #6d6e71;
    max-width: 60%;
    font-size: 1rem;
    margin-left: 160px;
  }
}
</style>
