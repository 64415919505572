import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    filters: {
      selectedRegion: ["Norte", "Centro", "Bajío", "Sur"],
      selectedIndustryType: [
        "Otras industrias manufactureras",
        "Industria indumentaria",
      ],
      selectedIndustrySize: [
        "Empresa mediana o grande",
        "Empresa pequeña",
        "Microempresa",
      ],
      selectedSex: ["Hombres", "Mujeres"],
      selectedLabourConditions: [
        "Acceso a préstamos",
        "Seguro médico privado",
        "Seguro de vida",
        "Fondo de retiro",
        "Jornada laboral semanal mayor a 40 horas",
      ],
      selectedEconomicUnit: "jobs",
      selectedMapIndustrySize: [
        "Empresa mediana o grande",
        "Empresa pequeña",
        "Microempresa",
      ],
    },
    selectedVariable: "region",
    regionData: [],
    industryTypeData: [],
    industrySizeData: [],
    dataSet: [],
    mapDataSet: [],
    filteredDataSet: [],
    filteredMapDataSet: [],
    chartType: "bar",
    labourConditions: [
      "Jornada laboral semanal mayor a 40 horas",
      "Ganan un salario mínimo o menos",
      "Ganan más de 5 salarios mínimos",
      "Sólo acceso a instituciones de salud",
      "Acceso a instituciones de salud y otras",
      "Pertenecen a un sindicato",
      "Tienen contrato por escrito",
      "Tienen contrato permanente",
      "Aguinaldo",
      "Vacaciones",
      "Reparto de utilidades",
      "Acceso a guarderías",
      "Tiempo para cuidados maternos o paterno",
      "Crédito para vivienda",
      "Fondo de retiro",
      "Seguro de vida",
      "Seguro médico privado",
      "Acceso a préstamos",
    ],
    region: [
      { text: "Norte", value: "Norte" },
      { text: "Bajío", value: "Bajío" },
      { text: "Centro", value: "Centro" },
      { text: "Sur", value: "Sur" },
      { text: "Nacional", value: "Nacional" },
    ],
    sex: [
      { text: "Mujeres", value: "Mujeres" },
      { text: "Hombres", value: "Hombres" },
      { text: "Ambas", value: "Ambas" },
    ],
    industrySize: [
      { text: "Empresa mediana o grande", value: "Empresa mediana o grande" },
      { text: "Empresa pequeña", value: "Empresa pequeña" },
      { text: "Microempresa", value: "Microempresa" },
      { text: "Todos los tamaños", value: "Todos los tamaños" },
    ],
    industrySizeMap: [
      { text: "Empresa mediana o grande", value: "Empresa mediana o grande" },
      { text: "Empresa pequeña", value: "Empresa pequeña" },
      { text: "Microempresa", value: "Microempresa" },
    ],
    industryType: [
      {
        text: "Otras industrias manufactureras",
        value: "Otras industrias manufactureras",
      },
      { text: "Industria indumentaria", value: "Industria indumentaria" },
      {
        text: "Todas las industrias manufactureras",
        value: "Todas las industrias manufactureras",
      },
    ],
    economicUnitsType: [
      {
        text: "Porcentaje de las empresas indumentarias en México que están en la entidad",
        value: "ii",
      },
      {
        text:
          "Porcentaje de las empresas de la entidad dedicadas a la industria indumentaria",
        value: "im",
      },
      {
        text: "Porcentaje de empleos en la entidad que dependen de la industria indumentaria",
        value: "jobs",
      },
      {
        text:
          "Porcentaje de las empresas manufactureras de la entidad dedicadas a la industria indumentaria",
        value: "tot",
      },
    ],
  },
  mutations: {
    setRegionFilter(state, value) {
      state.filters.selectedRegion = value;
    },
    setIndustryTypeFilter(state, value) {
      state.filters.selectedIndustryType = value;
    },
    setIndustrySizeFilter(state, value) {
      state.filters.selectedIndustrySize = value;
    },
    setMapIndustrySizeFilter(state, value) {
      state.filters.selectedMapIndustrySize = value;
    },
    setLabourConditionsFilter(state, value) {
      state.filters.selectedLabourConditions = value;
    },
    setSexFilter(state, value) {
      state.filters.selectedSex = value;
    },
    setEconomicUnitFilter(state, value) {
      state.filters.selectedEconomicUnit = value;
    },
    setRegionData(state, value) {
      state.regionData = value;
    },
    setIndustryTypeData(state, value) {
      state.industryTypeData = value;
    },
    setIndustrySizeData(state, value) {
      state.industrySizeData = value;
    },
    setDatasetData(state, value) {
      state.dataSet = value;
    },
    setMapDatasetData(state, value) {
      state.mapDataSet = value;
    },
    setFilteredDataSetData(state, value) {
      state.filteredDataSet = value;
    },
    setFilteredMapDataSetData(state, value) {
      state.filteredMapDataSet = value;
    },
    setSelectedVariableData(state, value) {
      state.selectedVariable = value;
    },
    setChartType(state, value) {
      state.chartType = value;
    },
  },
  actions: {},
  modules: {},
});
