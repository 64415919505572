<template lang="pug">
// Capítulos 1 (sin portada)
#cap-1.cap.section
  h2(id='introducción') Introducción
  #cap-1sub1.subsection-cap
    h3.mb-4
      span.subsection Objetivo del proyecto
    p
      | Si bien en algunas regiones del mundo se ha avanzado considerablemente en la defensa de los derechos de las personas que trabajan en la industria indumentaria y en la transparencia de políticas laborales de las empresas de la industria, en otras regiones del mundo, como Latinoamérica, es aún muy poca la información que tenemos al respecto. La información sobre las condiciones laborales en la industria de la confección en México es casi inexistente. No existen bases de datos completas o de fácil acceso que puedan ser utilizadas por las organizaciones de la sociedad civil para informar los esfuerzos para hacer cumplir los derechos de los trabajadores. Existe una falta de datos y homogeneidad entre las organizaciones del sector, y esto representa una barrera para la generación de cambios sistémicos de largo plazo y el desarrollo de una visión ecosistémica de las condiciones laborales en la industria de la confección en México.
    p
      | A pesar de la vasta experiencia de las organizaciones que trabajan en el campo y que se enfocan en áreas como la libertad sindical, la movilización por un salario digno y la no discriminación de las mujeres, sus acciones se centran en el trabajo comunitario, el litigio estratégico y la investigación relacionada.
    p
      | Con el apoyo de Fundación C&A, Fundación Avina busca abordar e identificar desafíos de la industria a través de la exploración de datos públicos que pueden proporcionar un medio para la identificación y recopilación de datos críticos, a través de los cuales se puede desarrollar una imagen más precisa del panorama de los derechos laborales. Este proyecto de investigación fue un primer acercamiento a la industria en México. Por un lado, desde Data Cívica, se hizo un acercamiento general a los datos públicos disponibles sobre el tema. Por otro lado, Matías Milia, doctor de FLACSO-México, se encargó de complementar estos hallazgos desde una perspectiva cualitativa que privilegió dos ejes: una reseña de las principales problemáticas del sector y un mapeo de sus actores representativos. A partir de la realización de entrevistas semi-estructuradas con estos actores, se procedió a una descripción cualitativa de las problemáticas observadas.
    p
      | Por medio de la información generada, se buscó establecer un panorama general sobre la cantidad y calidad de los empleos generados por esta industria, analizando – además- cómo las maquilas se relacionan con temas como la salud pública, la seguridad y la defensa de derechos humanos en México. A su vez, con base en esta información, se trató de identificar posibles áreas de oportunidad para mejorar las condiciones laborales de las personas que trabajan en esta industria. En este informe, estos hallazgos son complementados con valoraciones cualitativas y extractos resultado de la ronda de entrevistas realizadas con organizaciones y colectivos activos en el tema en distintas regiones de la República. De esta forma, este documento contiene los hallazgos principales de ambos estudios tomando en cuenta la riqueza de las fuentes de información cualitativas y cuantitativas.
</template>
<script>
export default {
  name:"Chapter1",
}
</script>
