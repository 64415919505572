<template lang="pug">
  #Navbar
    b-navbar.navbar.py-4.my-2(toggleable="lg" variant="faded" type="light")
      b-navbar-brand(href="https://datacivica.org/" target="blank")
        img(src="/assets/imgs/logo_dc.png" alt="logo data cívica")
      b-navbar-brand(href="https://www.avina.net/" target="blank")
        img(src="/assets/imgs/Fundacion_AvinaLogo.jpg" alt="logo fundación avina")
      b-navbar-brand(href="#")
        img(src="/assets/imgs/fundacion_logo.png" alt="logo fundación c & a")
      b-navbar-toggle(target="nav-collapse")
      b-collapse(id="nav-collapse" is-nav)
        b-navbar-nav.ml-auto.pr-5
          b-nav
            b-nav-item.mr-2(to="/" exact exact-active-class="active") La industria indumentaria en datos
            b-nav-item(to="/informe" exact exact-active-class="active") Informe


</template>
<script>
export default {
  name: "Cover"
};
</script>
<style lang="scss">
#Navbar {
  .navbar {
    img {
      height: 2rem;
      padding-left: 3rem;
    }
    .nav-link.active {
      color:#3e7ba9;
      font-weight: bold;
      border-bottom: solid 2px #f94098;
    }
  }
}
@media (max-width: 767px) {
  #Navbar {
    .navbar {
      img {
        height: 1rem;
        padding-left: 0.5rem;
      }
    }
  }
}
</style>