<template lang="pug">
#Bars
  .d-flex.my-4
    #chart1
    #chart2(v-bind:style="selectedSex.length >=2 ? border : ''")
  .d-flex.justify-content-center.legend
    #legend
</template>
<script>
import * as d3 from "d3";

export default {
  name: "Bars",
  data() {
    return {
      border: "border-left: solid 2px #b9babb;",
      config: {
        margin: {
          top: 5,
          right: 30,
          bottom: 5,
          left: 210
        },
        size: {
          width: 100,
          height: 400
        },
        legend: {
          width: 100,
          padding: 10
        }
      },
      bindTo1: "chart1",
      bindTo2: "chart2",
      chart1: {},
      chart2: {}
    };
  },
  computed: {
    dataSet() {
      return this.$store.state.filteredDataSet;
    },
    selectedSex() {
      return this.$store.state.filters.selectedSex;
    },
    selectedVariable() {
      return this.$store.state.selectedVariable;
    }
  },
  watch: {
    dataSet() {
      this.setChart();
    }
  },
  methods: {
    setChart() {
      if (d3.select("#Bars").node()) {
        this.config.size.width =
          (d3
            .select("#Bars")
            .node()
            .getBoundingClientRect().width -
            this.config.margin.left -
            this.config.margin.right) /
          this.selectedSex.length;
      }

      this.config.size.height =
        550 - this.config.margin.top - this.config.margin.bottom;
      const width = this.config.size.width,
        height = this.config.size.height,
        margin = this.config.margin;

      this.config.legend.width = d3
        .select("#Bars")
        .node()
        .getBoundingClientRect().width;

      d3.select("#" + this.bindTo1 + " svg").remove();
      d3.select("#" + this.bindTo2 + " svg").remove();
      d3.select("#legend svg").remove();

      const svg1 = d3
        .select("#" + this.bindTo1)
        .append("svg")
        .attr("width", width + margin.left)
        .attr("height", height + margin.bottom + margin.top);

      const g1 = svg1
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
      this.chart1["svg"] = g1;
      if (this.selectedSex.length > 1) {
        const svg2 = d3
          .select("#" + this.bindTo2)
          .append("svg")
          .attr("width", width + margin.right)
          .attr("height", height + margin.bottom + margin.top);
        const g2 = svg2
          .append("g")
          .attr("transform", `translate(${margin.right},${margin.top})`);
        this.chart2["svg"] = g2;
      }

      this.drawBars();
    },
    drawBars() {
      let data1;
      let data2;

      if (this.selectedSex.length > 1) {
        data1 = this.dataSet.filter(d => d.sex == this.selectedSex[0]);
        data2 = this.dataSet.filter(d => d.sex == this.selectedSex[1]);
      } else if (this.selectedSex.length == 1) {
        data1 = this.dataSet;
      } else {
        data1 = this.dataSet;
      }

      const keys = Object.keys(this.dataSet[0]).slice(2);
      const groupKey = Object.keys(this.dataSet[0])[1];

      let color = d3
        .scaleOrdinal()
        .range(["#00578e", "#8dc63f", "#ed0973", "#d1e2f3", "#FFB841"]);

      let x = d3
        .scaleLinear()
        .rangeRound([0, this.config.size.width - this.config.margin.right]);
      x.domain([0, 100]);

      let y0 = d3
        .scaleBand()
        .rangeRound([
          this.config.margin.top + 15,
          this.config.size.height - this.config.margin.bottom - 10
        ]);
      y0.domain(data1.map(d => d[groupKey])).padding(0.1);

      let y1 = d3
        .scaleBand()
        .rangeRound([y0.bandwidth(), 0])
        .padding(0.05);
      y1.domain(keys);

      let yAxis = g =>
        g
          .attr("transform", `translate(-20,0)`)
          .call(d3.axisLeft(y0))
          .call(g => g.select(".domain").remove());

      let xAxis = g =>
        g
          .attr(
            "transform",
            `translate(0,${this.config.size.height -
              this.config.margin.bottom -
              10})`
          )
          .call(d3.axisBottom(x).tickSizeOuter(0))
          .call(d3.axisBottom(x).ticks(4))
          .call(g => g.select(".domain").remove());

      let bars1 = this.chart1.svg
        .append("g")
        .selectAll("g")
        .data(data1)
        .join("g")
        .attr("transform", d => `translate(0,${y0(d[groupKey])})`);

      bars1
        .selectAll("rect")
        .data(d => keys.map(key => ({ key, value: d[key] })))
        .join("rect")
        .attr("x", x(0))
        .attr("y", d => y1(d.key))
        .attr("height", y1.bandwidth())
        .attr("width", d => d.value == "NA" ? 0 : x(d.value) - x(0))
        .attr("fill", d => color(d.key));

      bars1
        .selectAll("text")
        .data(d => keys.map(key => ({ key, value: d[key] })))
        .join("text")
        .attr("class", "bar-labels")
        .attr("x", d => d.value == "NA" ? 0 : x(d.value) + 10)
        .attr("y", d => y1(d.key) + y1.bandwidth() / 2 + 2.5)
        .text(d =>
          isNaN(d.value) ? "No disponible" : Math.round(d.value) + "%"
        );
      // .style("font-size", "11px" )

      this.chart1.svg
        .append("text")
        .attr("class", "chart-title")
        .attr("x", this.config.size.width / 2)
        .attr("y", 8)
        .text(data1[0].sex.toUpperCase())
        .style("text-anchor", "middle");

      this.chart1.svg
        .append("g")
        .attr("class", "yaxis")
        .call(yAxis)
        .selectAll(".tick text")
        .call(wrap, this.config.margin.left - 15);

      this.chart1.svg
        .append("g")
        .attr("class", "xaxis")
        .call(xAxis);

      // second chart
      if (this.selectedSex.length > 1) {
        let bars2 = this.chart2.svg
          .append("g")
          .selectAll("g")
          .data(data2)
          .join("g")
          .attr("transform", d => `translate(0,${y0(d[groupKey])})`);

        let xAxis = g =>
          g
            .attr(
              "transform",
              `translate(0,${this.config.size.height -
                this.config.margin.bottom -
                10})`
            )
            .call(d3.axisBottom(x).tickSizeOuter(0))
            .call(d3.axisBottom(x).ticks(4))
            .call(g => g.select(".domain").remove());

        bars2
          .selectAll("rect")
          .data(d => keys.map(key => ({ key, value: d[key] })))
          .join("rect")
          .attr("x", x(0))
          .attr("y", d => y1(d.key))
          .attr("height", y1.bandwidth())
          .attr("width", d => d.value == "NA" ? 0 : x(d.value) - x(0))
          .attr("fill", d => color(d.key));

        bars2
          .selectAll("text")
          .data(d => keys.map(key => ({ key, value: d[key] })))
          .join("text")
          .attr("class", "bar-labels")
          .attr("x", d => d.value == "NA" ? 0 : x(d.value) + 10)
          .attr("y", d => y1(d.key) + y1.bandwidth() / 2 + 2.5)
          .text(d =>
            isNaN(d.value) ? "No disponible" : Math.round(d.value) + "%"
          );

        this.chart2.svg
          .append("text")
          .attr("class", "chart-title")
          .attr("x", this.config.size.width / 2)
          .attr("y", 8)
          .text(data2[0].sex.toUpperCase())
          .style("text-anchor", "middle");

        this.chart2.svg
          .append("g")
          .attr("class", "xaxis")
          .call(xAxis);
      }
      //legend
      const padding = 10;

      let legend = d3
        .select("#legend")
        .append("svg")
        .attr(
          "width",
          this.selectedVariable == "region"
            ? 339
            : this.selectedVariable == "industryType"
            ? 724
            : 649
        )
        .attr("height", 30)
        .append("g")
        .attr("transform", "translate(" + 0 + "," + 0 + ")")
        .selectAll("g")
        .data(keys)
        .enter()
        .append("g");

      legend
        .append("rect")
        .attr("width", 14)
        .attr("height", 14)
        .attr("fill", color);

      legend
        .append("text")
        .attr("class", "bar-legend")
        .attr("x", 18)
        .attr("y", 10)
        .attr("dy", ".15em")
        .text(d => d);

      legend.attr("transform", function(d, i) {
        return (
          "translate(" +
          (d3.sum(keys, function(e, j) {
            if (j < i) {
              return legend.nodes()[j].getBBox().width;
            } else {
              return 0;
            }
          }) +
            padding * i) +
          ",0)"
        );
      });

      // axis label wrap
      function wrap(text, width) {
        text.each(function() {
          var text = d3.select(this),
            words = text
              .text()
              .split(/\s+/)
              .reverse(),
            word,
            line = [],
            lineNumber = 0,
            lineHeight = 1.1, // ems
            y = text.attr("y"),
            dy = parseFloat(text.attr("dy")),
            tspan = text
              .text(null)
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", dy + "em");
          while ((word = words.pop())) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text
                .append("tspan")
                .attr("x", 0)
                .attr("y", y)
                .attr("dy", `${++lineNumber * lineHeight + dy}em`)
                .text(word);
            }
          }
        });
      }
    }
  },
  mounted() {
    this.setChart();
  }
};
</script>
<style lang="scss">
#Bars {
  .bar-labels {
    font-size: 13px;
    fill: #00578e;
    font-weight: bold;
  }
  .bar-legend {
    fill: #6d6e71;
    font-size: 14px;
    font-weight: bold;
  }
  .legend {
    margin-left: 165px;
  }
}
</style>
