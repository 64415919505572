<template lang="pug">
#Filters.mb-4
  .container.filters.default-back.neutral-color.px-4.py-5(
    v-if="chartType !== 'map'"
  )
    p.small-text.bolder.mx-1.mb-4 Desagrega por:
    .card.option-group.small-text.py-2.px-3.my-3.mx-1
      .card-header.d-flex.justify-content-between.p-0
        p.bolder Sexo
      b-form-group.mb-0
        b-form-checkbox.mr-4(
          inline,
          size="lg",
          v-model="selectedSex",
          v-for="option in sex",
          @change="filterDataSet('sex', $event)",
          :value="option.value",
          :disabled="(selectedSex.length >= 2 && selectedSex.indexOf(option.value) === -1) || (selectedSex.length == 1 && selectedSex.indexOf(option.value) !== -1)"
        ) {{ option.text }}
        p.limit.text-center.my-0(v-if="selectedSex.length >= 2") Visualiza hasta dos sexos 
    b-dropdown.mt-1.mb-2(no-caret)
      template(#button-content)
        img.add-btn.mr-2(src="/assets/imgs/add.png")
        span.neutral-color.small-text.bolder Cambiar variable
      b-dropdown-item-button(
        v-if="selectedVariable !== 'industrySize'",
        v-on:click="changeSelectedVariable('industrySize')"
      ) Tamaño de la empresa
      b-dropdown-divider
      b-dropdown-item-button(
        v-if="selectedVariable !== 'industryType'",
        v-on:click="changeSelectedVariable('industryType')"
      ) Tipo de industria
      b-dropdown-divider
      b-dropdown-item-button(
        v-if="selectedVariable !== 'region'",
        v-on:click="changeSelectedVariable('region')"
      ) Región
    .card.option-group.small-text.py-2.px-3.my-3.mx-1(
      v-if="selectedVariable == 'region'"
    ) 
      .card-header.d-flex.justify-content-between.p-0
        p.bolder Región
      b-form-group.mb-0.checkbox-region
        b-form-checkbox.mr-4(
          inline
          size="lg",
          v-model="selectedRegion",
          v-for="option in region",
          @change="setData('region', $event)",
          :value="option.value",
          :disabled="(selectedRegion.length == 1 && selectedRegion.indexOf(option.value) !== -1)"
        ) {{ option.text }}
    .card.option-group.small-text.py-2.px-3.my-3.mx-1(
      v-if="selectedVariable == 'industrySize'"
    )
      .card-header.d-flex.justify-content-between.p-0
        p.bolder Tamaño de la empresa
      b-form-group.mb-0
        b-form-checkbox.mr-4(
          size="lg",
          v-model="selectedIndustrySize",
          v-for=" option in industrySize",
          @change="setData('size', $event)",
          :value="option.value",
          :disabled="(selectedIndustrySize.length == 1 && selectedIndustrySize.indexOf(option.value) !== -1)"
        ) {{ option.text }}
    .card.option-group.small-text.py-2.px-3.my-3.mx-1(
      v-if="selectedVariable == 'industryType'"
    )
      .card-header.d-flex.justify-content-between.p-0
        p.bolder Tipo de industria
      b-form-group.mb-0
        b-form-checkbox.mr-4(
          size="lg",
          v-model="selectedIndustryType",
          v-for="option in industryType",
          @change="setData('type', $event)",
          :value="option.value",
          :disabled="(selectedIndustryType.length == 1 && selectedIndustryType.indexOf(option.value) !== -1)"
        ) {{ option.text }}
    
    p.bolder.small-text.mx-1 Condiciones laborales:
    .card.option-group.small-text.my-2.mx-1
        b-form-tags(
          v-model="selectedLabourConditions",
          no-outer-focus,
          :limit="limitLabourConditions",
          @input="filterDataSet('labour', $event)"
        )
          template(v-slot="{ tags, disabled, addTag, removeTag }")
            ul.list-inline.d-inline.mb-2(v-if="tags.length > 0")
              li.pills-list(v-for="tag in tags", :key="tag")
                b-form-tag(
                  @remove="removeTag(tag)",
                  :title="tag",
                  :disabled="disabled",
                  variant="info"
                ) {{ tag }}
            b-dropdown(size="sm", no-caret, block, dropup)
              template(#button-content)
                p.limit.text-center.my-1 Visualiza hasta seis prestaciones
              b-dropdown-item-button(
                v-for="option in availableLabourConditions",
                :key="option",
                @click="onOptionClick({ option, addTag })"
              )
                | {{ option }}
              

  .container.filters.default-back.neutral-color.px-4.py-5(
    v-if="chartType == 'map'"
  )
    p.small-text.bolder.mx-1.mt-5 Selecciona variable a visualizar:
    b-form-radio-group.ml-1.my-4(
      v-model="selectedEconomicUnit",
      :options="economicUnitsType",
      stacked,
      @change="setMapData('unit', $event)"
    )
    .card.option-group.small-text.py-2.px-3.mx-1.mb-5
      .card-header.d-flex.justify-content-between.p-0
        p.bolder Tamaño de la empresa
      b-form-group.mb-0
        b-form-checkbox.mr-4(
          size="lg",
          v-model="selectedMapIndustrySize",
          v-for="option in industrySizeMap",
          @change="filterMapDataSet('size', $event)",
          :value="option.value",
          :disabled="(selectedMapIndustrySize.length == 1 && selectedMapIndustrySize.indexOf(option.value) !== -1)"
        ) {{ option.text }}
</template>
<script>
import * as d3 from "d3";

export default {
  name: "Filters",
  data() {
    return {
      limitLabourConditions: 6,
      regionData: [],
      industryTypeData: [],
      industrySizeData: [],
      selectedData: [],
      mapData: [],
    };
  },
  computed: {
    availableLabourConditions() {
      const labourConditions = this.labourConditions.filter(
        opt => this.selectedLabourConditions.indexOf(opt) === -1
      );
      return labourConditions;
    },
    labourConditions() {
      return this.$store.state.labourConditions;
    },
    region() {
      return this.$store.state.region;
    },
    sex() {
      return this.$store.state.sex;
    },
    industrySize() {
      return this.$store.state.industrySize;
    },
    industrySizeMap() {
      return this.$store.state.industrySizeMap;
    },
    industryType() {
      return this.$store.state.industryType;
    },
    economicUnitsType() {
      return this.$store.state.economicUnitsType;
    },
    chartType() {
      return this.$store.state.chartType;
    },
    selectedRegion: {
      get() {
        return this.$store.state.filters.selectedRegion;
      },
      set(value) {
        this.$store.commit("setRegionFilter", value);
      }
    },
    selectedIndustryType: {
      get() {
        return this.$store.state.filters.selectedIndustryType;
      },
      set(value) {
        this.$store.commit("setIndustryTypeFilter", value);
      }
    },
    selectedIndustrySize: {
      get() {
        return this.$store.state.filters.selectedIndustrySize;
      },
      set(value) {
        this.$store.commit("setIndustrySizeFilter", value);
      }
    },
    selectedLabourConditions: {
      get() {
        return this.$store.state.filters.selectedLabourConditions;
      },
      set(value) {
        this.$store.commit("setLabourConditionsFilter", value);
      }
    },
    selectedSex: {
      get() {
        return this.$store.state.filters.selectedSex;
      },
      set(value) {
        this.$store.commit("setSexFilter", value);
      }
    },
    selectedEconomicUnit: {
      get() {
        return this.$store.state.filters.selectedEconomicUnit;
      },
      set(value) {
        this.$store.commit("setEconomicUnitFilter", value);
      }
    },
    selectedMapIndustrySize: {
      get() {
        return this.$store.state.filters.selectedMapIndustrySize;
      },
      set(value) {
        this.$store.commit("setMapIndustrySizeFilter", value);
      }
    },
    dataSet: {
      get() {
        return this.$store.state.dataSet;
      },
      set(value) {
        this.$store.commit("setDatasetData", value);
      }
    },
    mapDataSet: {
      get() {
        return this.$store.state.mapDataSet;
      },
      set(value) {
        this.$store.commit("setMapDatasetData", value);
      }
    },
    filteredDataSet: {
      get() {
        return this.$store.state.filteredDataSet;
      },
      set(value) {
        this.$store.commit("setFilteredDataSetData", value);
      }
    },
    filteredMapDataSet: {
      get() {
        return this.$store.state.filteredMapDataSet;
      },
      set(value) {
        this.$store.commit("setFilteredMapDataSetData", value);
      }
    },
    selectedVariable: {
      get() {
        return this.$store.state.selectedVariable;
      },
      set(value) {
        this.$store.commit("setSelectedVariableData", value);
      }
    },
  },
  watch: {
    selectedVariable() {
      this.selectData(this.selectedVariable);
    }
  },
  methods: {
    onOptionClick({ option, addTag }) {
      addTag(option);
    },
    changeSelectedVariable(value) {
      this.selectedVariable = "";
      this.selectedVariable = value;
    },
    selectData(selectedVariable) {
      if (this.chartType !== "map") {
        this.selectedVariable = selectedVariable;
        switch (selectedVariable) {
          case "region":
            this.selectedData = this.regionData;
            this.dataSet = this.regionData;
            this.setData("region", this.selectedRegion);
            break;
          case "industryType":
            this.selectedData = this.industryTypeData;
            this.dataSet = this.industryTypeData;
            this.setData("type", this.selectedIndustryType);
            break;
          case "industrySize":
            this.selectedData = this.industrySizeData;
            this.dataSet = this.industrySizeData;
            this.setData("size", this.selectedIndustrySize);
            break;
          default:
            this.selectedData = this.regionData;
            this.dataSet = this.regionData;
        }
      }
    },
    setData(filter, value) {
      this.dataSet = this.selectedData.map(d => {
        const obj = { sex: d.sex, labour: d.labour };
        value.forEach(e => {
          if (Object.keys(d).some(current => current == e)) obj[e] = d[e];
        });
        return obj;
      });
      this.filterDataSet("sex", this.selectedSex);
      this.filterDataSet("labour", this.selectedLabourConditions);
    },
    setMapData(filter, value) {
      let data = this.mapData;
      this.mapDataSet = data.map(d => {
        const obj = {
          state: d.nom_ent,
          size: d.tam,
          state_code: d.ent,
          value: d[value],
          total: d["total_" + value],
          subtotal: d["sub_" + value]
        };
        return obj;
      });
      this.filterMapDataSet("size", this.selectedMapIndustrySize);
    },
    filterMapDataSet(filter, value) {
      let size = filter == "size" ? value : this.selectedMapIndustrySize;

      this.filteredMapDataSet = this.mapDataSet.filter(d => {
        let sizeCondition =
          size == null ? true : size.find(st => d["size"].indexOf(st) > -1);
        let allConditions = sizeCondition;
        return allConditions;
      });
    },
    filterDataSet(filter, value) {
      let sex = filter == "sex" ? value : this.selectedSex;
      let labour = filter == "labour" ? value : this.selectedLabourConditions;

      this.filteredDataSet = this.dataSet.filter(d => {
        let sexCondition =
          sex == null ? true : sex.find(st => d["sex"].indexOf(st) > -1);
        let labourCondition =
          labour == null
            ? true
            : labour.find(st => d["labour"].indexOf(st) > -1);

        let allConditions = sexCondition && labourCondition;
        return allConditions;
      });
    },
    async getData() {
      this.mapData = await d3.csv("/assets/csv/mapa2.csv", d => {
        return d;
      });
      this.regionData = await d3.csv("/assets/csv/region_final.csv", d => {
        return d;
      });
      this.industryTypeData = await d3.csv("/assets/csv/type_final.csv", d => {
        return d;
      });
      this.industrySizeData = await d3.csv("/assets/csv/size_final.csv", d => {
        return d;
      });
    }
  },
  async mounted() {
    await this.getData();
    await this.setMapData("unit", "jobs");
    this.selectData(this.selectedVariable);
  }
};
</script>
<style lang="scss">
#Filters {
  // .filters {
  //   min-height: 80vh;
  //   align-items: center;
  // }
  ul li {
    margin-bottom: auto;
  }
  .option-group {
    border: 1.5px solid white;
    background-color: transparent;
    border-radius: 2px;
    .custom-control {
      padding-bottom: 0.5rem;
    }
    .custom-control-inline {
      width: 107px;
    }
    .custom-checkbox .custom-control-label::before {
      border-radius: 0.15rem;

      background-color: transparent;
      border: #fff solid 1.5px;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #fff;
      background-color: #fff;
    }
    .custom-control-label {
      font-size: 1.1rem;
    }
    .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
      color: #ffffff;
      // text-decoration: line-through;
    } 
    .custom-checkbox
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23477baa' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    }
    .card-header {
      background-color: transparent;
      border: none;
    }
  }
  .add-btn {
    max-width: 28px;
    height: auto;
  }
  .dropdown-divider {
    margin: 0;
    background-color: #3e7ba9;
  }
  .dropdown-item {
    color: #3e7ba9;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 0.9rem;
    border-bottom: solid 0.5px #fff;

  }
  .dropdown-toggle {
    white-space: normal;
  } 
  .dropdown-menu {
    border-radius: 7px;
    max-width: 105%;
    background-color: #dde6eb;
     max-height: 250px;
    overflow-y: scroll;
    padding: 0;
  }
 

  .pills-list {
    margin-bottom: 5px;
  }
  .badge-info {
    color: #00578e;
    background-color: #fff;
    border-radius: 6.4px;
    border: solid 0.8px #ed0973;
    font-weight: 700;
    font-size: 1rem;
  }
  .badge {
    padding: 0.15em 0.4em;
  }
  .b-form-tag > button.b-form-tag-remove {
    color: #333232;
    font-size: 145%;
  }
  .close {
    opacity: 1;
  }
  .form-control {
    border: none;
    background-color: transparent;
  }
  .custom-select {
    color: #fff;
    background-color: transparent;
  }
  .custom-radio {
    margin: 1rem auto;
  }
  button {
    font-size: 1rem;
  }
  .btn-secondary {
    background-color: transparent;
    border: none;
  }
  .sex-filter {
    flex-wrap: wrap;
  }
  .limit {
    font-size: 1rem;
    font-style: italic;
  }
}
</style>
