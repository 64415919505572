<template lang="pug">
#Report
  //- iframe(src="/assets/informe.html")
  header#portada.d-flex.justify-content-center.text-center
    h1
      | UNA MIRADA DESDE LOS DATOS CUANTITATIVOS Y CUALITATIVOS A LA 
      span INDUSTRIA INDUMENTARIA EN MÉXICO
      hr
    h4.mt-0 Noviembre 2020
  b-container.xl-container(fluid).mt-5
        .row
          #menu.col-12.col-md-3.mb-3
            Sidenav
          section.col-12.col-md-9
            Chapter1
            Chapter2
            Chapter3
            Chapter4
            Chapter5
            Chapter6
            Chapter7

  
</template>

<script>
import Sidenav from "@/components/Sidenav.vue";
import Chapter1 from "@/components/chapters/Chapter1.vue";
import Chapter2 from "@/components/chapters/Chapter2.vue";
import Chapter3 from "@/components/chapters/Chapter3.vue";
import Chapter4 from "@/components/chapters/Chapter4.vue";
import Chapter5 from "@/components/chapters/Chapter5.vue";
import Chapter6 from "@/components/chapters/Chapter6.vue";
import Chapter7 from "@/components/chapters/Chapter7.vue";
export default {
  name: "Report",
  components: {
    Sidenav,
    Chapter1,
    Chapter2,
    Chapter3,
    Chapter4,
    Chapter5,
    Chapter6,
    Chapter7
  },
  data() {
    return {
      sectionObserver: null
    }
  },
  methods: {
    menuEffect(section) {
        const allSubsections = document.querySelectorAll(".sub.subtitle");
      
        allSubsections.forEach(sub => {
          sub.classList.remove("active");
          sub.style.display = "none";
        });
        const sections = document.querySelectorAll(".title.nav-link");
        sections.forEach((s) => {
          s.classList.remove("active");
        });
        const sectionId = section
        // subsection
        const child = document.getElementsByClassName(sectionId)[0]
        // section
        const parent = child.parentElement.parentElement
        
        parent.childNodes[0].classList.add("active");
        child.classList.add("active");
        const children = parent.childNodes[1].childNodes
        children.forEach(child => {
            child.style.display = "block";
          })

    },
    observeSections() {
    try {
      this.sectionObserver.disconnect()
    } catch (error) {
      console.log(error)
    }

    const options = {
      rootMargin: '0px 0px',
      threshold: 0
    }
    this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)

    // Observe each subsection
    const sections = document.querySelectorAll('.subsection-cap')
    sections.forEach(section => {
      this.sectionObserver.observe(section)
    })
  },
  sectionObserverHandler (entries) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
         const sectionId = entry.target.id
         this.menuEffect(sectionId)
      }
    }
  }

  },
  mounted () {
  this.observeSections()
},
};
</script>

<style lang="scss" src="@/informe.scss">
</style>