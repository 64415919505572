<template lang="pug">
#Download.justify-content-center.py-4
  b-button.download-btn.mx-2.px-2(@click="downloadCSV()") Descargar base de datos
  b-button.download-btn.mx-2.px-2(@click="downloadIMG('png')") Descargar gráfica como imagen
  b-button.download-btn.mx-2.px-2(@click="downloadIMG('svg')") Descargar gráfica como svg
</template>
<script>
import * as htmlToImage from "html-to-image";

export default {
  name: "download",
  computed: {
    dataSet() {
      return this.$store.state.filteredDataSet;
    },
    mapDataSet() {
      return this.$store.state.filteredMapDataSet;
    },
    chartType() {
      return this.$store.state.chartType;
    }
  },
  methods: {
    downloadIMG(format) {
      let node = document.getElementById("Charts");
      if (format == "png") {
        htmlToImage
          .toPng(node)
          .then(function(dataUrl) {
            var link = document.createElement("a");
            link.download = "industria-indumentaria.png";
            link.href = dataUrl;
            link.click();
          })
          .catch(function(error) {
            console.error("error en descarga de imagen", error);
          });
      } else {
        htmlToImage
          .toSvg(node)
          .then(function(dataUrl) {
            var link = document.createElement("a");
            link.download = "industria-indumentaria.svg";
            link.href = dataUrl;
            link.click();
          })
          .catch(function(error) {
            console.error("error en descarga de svg", error);
          });
      }
    },
    downloadCSV() {
      let data = this.chartType == "map" ? this.mapDataSet : this.dataSet;

      let csv = "";
      let headers = Object.keys(data[0]).join(",");
      let values = data.map(o => Object.values(o).join(",")).join("\n");

      csv += headers + "\n" + values;
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.href = url;
      link.download = "datos-industria-indumentaria.csv";
      link.click();
    }
  }
};
</script>
<style lang="scss">
#Download {
  display: flex;
}
</style>
