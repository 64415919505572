<template lang="pug">
#cap-5.section 
  // Capítulo 5 (Portada)
  #banner-5.banner
      .container-fluid
        .row
          .col-12.col-md-7.mx-0.px-0
            img.banner-img(src="/assets/Portada Cap 3 copy.jpg")
          .banner-col.col-12.col-md-5.d-flex.justify-content-center.align-items-center
            h5.banner-title.mt-3.pb-2.mx-4
              | COVID: 
              div.boldest La pandemia como desafío emergente
  //- #banner-5.banner
  //-   h2.banner-title
  //-     | COVID: 
  //-     span La pandemia como desafío emergente
          // Capítulo 5
  #cap-5sub1.subsection-cap.cap.mt-5
    p
      | Muchas de las condiciones estructurales hasta aquí descritas se han profundizado en el actual contexto de crisis sanitaria. En el caso de la organización de las trabajadoras y el reclamo por algunos derechos laborales concretos que puedan estar siendo vulnerados, la pandemia ha potenciado la limitación que muchas trabajadoras se imponen a si mismas al momento de denunciar las injusticias que sufren.
    blockquote
      p.quote-body
        | (…) ha sido muy difícil lo que ha estado pasando por lo de la pandemia. Es que no quieren, no quieren exponerse al peligro. No quieren salir a las calles. Del trabajo a la casa… entonces, pasa alguna violación en sus derechos y no hablan, no exigen. O sea, muchas por miedo, otras porque lo que ellas quieren es salir de eso.
      p.quote-footer
        | —ENTREVISTA 03; Activista DHL, Mujer, Coahuila
    p
      | Los efectos que ha tenido la pandemia han calado hondo sobre las brechas de género preexistentes y las han potenciado. Un aspecto clave es la incompatibilidad que se ha planteado entre el trabajo remunerado de las mujeres y las labores de cuidado que, al no contar con el soporte que brinda el sistema educativo, deben resolverse de otra forma.
    blockquote
      p.quote-body
        | Ahora en tiempo de pandemia vimos que las más afectadas son las mujeres: si bien se reactivó la economía y se reabrieron las fábricas que se habían suspendido, las escuelas y las guarderías no se abrieron, lo que obviamente ocasiona mucho estrés y mucha angustia en las mujeres porque algunas no tienen un sistema de apoyo familiar para poder dejar a los hijos seguros y están dejando a los niños más pequeños en cuidado de los hijos más grandes, es decir, niños cuidan niños. Estas son unas de las cosas que no se consideraron al organizar el regreso de esta pandemia.
      p.quote-footer
        | —ENTREVISTA 08; Activista DHL, Mujer, Tamaulipas - Coahuila - Chihuahua
    p
      | También los despidos de personal han mostrado características ya conocidas, como la arbitrariedad y orientación precisa de los mismos. La misma discrecionalidad en las decisiones sobre el personal ha estado presente tanto en la desvinculación como al momento de retomar labores en las empresas. La merma en los ingresos percibidos ha sido otro aspecto destacado. En los casos en que se ha mantenido a la gente como empleados esto ha implicado una merma considerable en los ingresos, ya que estos son variables y están sujetos a la productividad de las trabajadoras.
    p
      | Se informa que las medidas para evitar brotes y contagios en empresas han sido movilizadas, sobre todo, por empresas grandes con contratos o de capitales internacionales. Pero el apego a las normas de Sana Distancia e higiene no han sido homogéneas.

</template>
<script>

export default {
  name:"Chapter5",
}
</script>