<template lang="pug">
  #cap-3.section
    // Capítulo 3 (Portada)
    #banner-3.banner
      .container-fluid
        .row
          .col-12.col-md-8.mx-0.px-0
            img.banner-img(src="/assets/Portada Cap 1 copy.png")
          .banner-col.col-12.col-md-4.d-flex.justify-content-center.align-items-center
            h5.banner-title.mt-3.pb-2
              | Las condiciones laborales de las
              div.boldest trabajadoras de la industria indumentaria
              
      //- h2.banner-title
      //-   | Las condiciones laborales de las 
      //-   span trabajadoras de la industria indumentaria
    // Capítulo 3
    #cap-3sub1.cap.subsection-cap
      h3.mb-4
        span.subsection Precariedad laboral generalizada, pero
        br
        span.subsection  aún más profunda para las mujeres 
      p
        | De acuerdo con el DENUE, en México existen más de 40 mil unidades económicas que se dedican a la industria indumentaria. El sector emplea alrededor de 740,000 personas en el país. La industria genera una gran cantidad de empleos, pero al comparar estos empleos con otros en la misma industria manufacturera, es evidente que ofrecen menos prestaciones y peores salarios. Asimismo, de acuerdo con la ENOE, la industria indumentaria es la industria manufacturera que más mujeres emplea en México. No obstante, los datos reflejan que la brecha de género, de por sí presente en otras industrias manufactureras, se agrava en la industria indumentaria. Mientras en el resto de la industria manufacturera sólo una tercera parte de las mujeres gana hasta un salario mínimo, en la industria indumentaria el 42% gana esa cantidad. En esta gráfica también podemos ver que la brecha entre hombres y mujeres que ganan más de uno y hasta tres salarios mínimos se extiende en la industria indumentaria.
      img.responsive(src="assets/Graf_1.jpg")
      p
        | Sobre qué explica la brecha de género tan consistente en los datos, las entrevistas describen prácticas de contratación que perfilan las obreras. Estos perfiles reconocen, profundizan y se benefician de las carencias que ciertos perfiles demográficos tienen. Así lo explica, en el siguiente pasaje, una informante:
      blockquote
        p.quote-body
          | (…) la mayoría de las mujeres que trabajan en la maquila, son mujeres que tienen un perfil perfectamente identificado por los empleadores de las grandes maquilas. (…) [primero,] son mujeres jóvenes desde los 35 hasta los 50 años. A las mujeres mayores de 50 años ya les cuesta trabajo insertarse porque las maquilas saben que eso implica el que ellas están esperando jubilarse; dos, son mujeres con un grado de educación corto, es decir, pueden llegar a tener la secundaria o preparatoria trunca. Con esta característica, los empleadores aseguran que ellas, por necesidad, no van a exigir más; tres, son mujeres jefas o líderes de familia –familias monoparentales–, por lo que ellas son quienes sostienen la casa y quienes llevan el único ingreso económico a la familia. Esta es una situación para poder vulnerabilizar (sic) aún más a las mujeres, en este caso, a las mujeres jóvenes.
        p.quote-footer
          | —ENTREVISTA 14; Activista DHL, Mujer, Estado de Hidalgo
      p
        | Los datos también indican que hay más informalidad laboral en la industria indumentaria que en otras industrias manufactureras. Mientras que en otras industrias manufactureras más de la mitad de hombres y mujeres tienen un contrato permanente y un contrato por escrito, en la industria indumentaria menos de la mitad los tienen.
      p
        | Según el estudio cualitativo, la informalidad y la contratación de terceras partes en las tareas de producción es una práctica común, pero sus condiciones dependen en parte del territorio. Un ejemplo de esto es la figura emergente del ‘home-working’ por medio del cual comunidades y hogares son subcontratadas para realizar tareas específicas de la industria:
      blockquote
        p.quote-body
          | Trabajamos en una maquiladora y vimos que en ella sí había costureras que trabajaban desde casa y también hicimos acompañamiento a estos talleres que están en casa. Nos dimos cuenta que, en cuanto a prestaciones y demás, sí hay una relación en la que conforme el trabajador se va alejando más de la empresa o de un lugar formal de trabajo, los derechos se vean vulnerando aún más.
        p.quote-footer
          | —ENTREVISTA 05; Activista DHL, Mujer, CDMX
      p
        | En estos casos, la paga suele ser a destajo, es decir, contra entrega de las piezas confeccionadas. De esta forma, el ‘homeworking’ se beneficia de los recursos financieros y del capital social de quienes forman parte de las unidades productivas para su efectividad.
      p
        | También cabe mencionar que en la informalidad también hay brecha de género. Esta brecha en el acceso a un contrato por escrito es alrededor de tres veces más grande en la industria indumentaria, donde aproximadamente sólo una de cada tres mujeres tiene estos contratos. En la industria indumentaria también hay muchos menos empleadas afiliadas a un sindicato, lo que también puede ser un obstáculo para exigir mejores condiciones laborales.
      img.responsive(src="assets/Graf_2.jpg")
      p
        | En parte como consecuencia de esto, los datos muestran que las personas que trabajan en la industria indumentaria también tienen menor acceso a otro tipo de prestaciones de ley. El caso de las vacaciones es un buen indicador del tipo de que las obligaciones legales para otorgar beneficios a las obreras se cumplen discrecionalmente. En los testimonios, se explica de esta forma:
      blockquote
        p.quote-body
          | Incluso derechos más básicos, que a nosotros nos parecería que no hay violaciones al respecto, pero que son escandalosamente no cumplidos. Como el tema de las vacaciones… [que] todos los contratos lo establecen. Algunos, incluso, hasta dan días de más y tal. Pero, como tal, es difícil, muy difícil, encontrar fábricas que den el período completo a las trabajadoras. O sea, de hecho, hay gente en las fábricas trabajando que te dicen ‘pues yo mis vacaciones son los días festivos, o cuando la empresa cierra navidad’. Pero es más un descanso forzoso que la planta implementa por cerrar o parar operaciones, y es muy raro. La gente nunca puede tomarse un período de vacaciones. O en la prima vacacional es también algo que en la práctica es inexistente, aunque en los contratos aparezca.
        p.quote-footer
          | —ENTREVISTA 10; Activista DHL, Mujer, CDMX
      p
        | Lo mismo se repite en relación a otros beneficios. Por ejemplo, a pesar de que el aguinaldo y los días de vacaciones pagados son obligatorios por ley, sólo alrededor del 30% de las mujeres y la mitad de los hombres de la industria indumentaria gozan de estos derechos. En el caso del reparto de utilidades, el acceso es aún menor y sólo una de cada diez mujeres y dos de cada diez hombres que trabajan en esta industria reciben este tipo de utilidades. El acceso a fondos de retiro y créditos para vivienda también está limitado a una de cada cuatro mujeres y a menos de uno de cada dos hombres. De nuevo, la brecha de género en el acceso a estas prestaciones es al menos dos veces más grande en la industria indumentaria.
      img.responsive(src="assets/Graf_3.jpg")
      p
        | Las únicas prestaciones en las que no hay brecha de género en favor de los hombres son el acceso a guarderías y el tiempo de cuidados maternos o paternos, prestaciones tradicionalmente asociadas al rol que desempeñan las mujeres en el hogar. Esto contrasta, hasta cierto punto con lo encontrado en el ejercicio cualitativo. Algunos testimonios comentaron de hecho que en algunos contratos se especifica que se respetarán los derechos de paternidad, pero no se especifica nada sobre maternidad. Si bien esto se justifica porque lo de maternidad está en la ley y, supuestamente, ya no es necesario aclararlo, algunas personas ven en esto una prueba más de las asimetrías entre hombres y mujeres.
      blockquote
        p.quote-body
          | Usualmente los contratos suelen contener una cláusula en algún momento del texto que dice que todo lo demás que no se menciona en el contrato está de acuerdo a lo que dice la Ley Federal del Trabajo. Entonces con eso es como que se amparan y cubren el decir ‘No es que no cumplamos con los derechos de maternidad, pero como ya están en la Ley pues son como de cajón y por eso los obviamos y ni siquiera nos parece importante ponerlo dentro del contrato colectivo’. Pero sí hay derechos de paternidad en contraste, ¿no? Entonces esa es una de las cosas que más yo resaltaría en este momento que me parecían más curiosas de los hallazgos ¿no?
        p.quote-footer
          | —ENTREVISTA 10; Activista DHL, Mujer, CDMX
    #cap-3sub2.cap.subsection-cap
      h3.mb-4
        span.subsection La especialización por región y género
        br 
        span.subsection explica en parte la desigualdad
      p
        | Si bien a nivel nacional sólo alrededor del 1.3% de la población ocupada
        //- a#fnref1.footnote-ref(href='#fn1')
        span.superscript#fnref1 1
        |  trabaja en la industria indumentaria, este porcentaje varía entre entidades federativas. De acuerdo con la ETOE de Junio 2020, el estado en el que un mayor número de mujeres ocupadas son empleadas por la industria indumentaria es Tlaxcala, donde alrededor del 11% de las mujeres ocupadas están empleadas en este sector. Después de Tlaxcala, el siguiente estado con más mujeres empleadas en la industria indumentaria es Yucatán (5%), seguido de Puebla e Hidalgo (ambos con 4%) y Aguascalientes (3%). El porcentaje de hombres empleados en la industria indumentaria es siempre menor. En su caso, también lidera Tlaxcala, pero con un 5%. Después le siguen Puebla, Hidalgo, Durango y el Estado de México, todos con sólo 2% de los hombres ocupados.
        b-tooltip(triggers="click hover" target="fnref1" placement="top") <p> Según la ENOE, la población ocupada la conforman personas de 15 y más años de edad que en la semana de referencia realizaron alguna actividad económica durante al menos una hora. Incluye a los ocupados que tenían trabajo, pero no lo desempeñaron temporalmente por alguna razón, sin que por ello perdieran el vínculo laboral con este; así como a quienes ayudaron en alguna actividad económica sin recibir un sueldo o salario. </p>
      p
        | En este sentido habría que mencionar que, de acuerdo con el ejercicio cualitativo, hombres y mujeres tienen distintos roles en la cadena de valor de producción de la indumentaria. Esta involucra la producción de telas y tejidos; su corte, teñido y preparado; y, por último, su confección, acabado y aplicación de detalles ornamentales. Estas tres etapas varían en la intensidad de trabajo, el uso de tecnología y el volumen de inversión requerida para montar un establecimiento industrial. Las mujeres laboran, sobre todo, en el eslabón que más mano de obra requiere: la confección. Tareas tecnificadas e intensivas en capital y con mejores remuneraciones son ocupadas por hombres.
      blockquote
        p.quote-body
          | [El peso de la mano de obra en la elaboración de telas] Es mucho menos intensiva en mano de obra y, también, mucho más masculinizada. Los salarios tienden a ser más altos, los riesgos profesionales son otros.
        p.quote-footer
          | —ENTREVISTA 10; Activista DHL, Mujer, CDMX
      img.responsive(src="assets/Graf_4.jpg")
      p
        | Otro hallazgo interesante al ver los datos del DENUE es que, en México, la mayoría de las unidades económicas dedicadas a la industria indumentaria son microempresas, es decir, tienen cinco empleados o menos. No obstante, esto varía mucho dependiendo del estado. Por ejemplo, el Estado de México concentra el mayor número de unidades económicas con 251 empleados o más y el estado de Oaxaca el mayor número de las unidades económicas con máximo cinco empleados.
      p
        | Para hacer este análisis de manera más eficiente, dividimos al país en cinco regiones y después analizamos las características de las empresas en cada una de ellas
        //- a#fnref2.footnote-ref(href='#fn2')
        span.superscript#fnref 2
        | . En este sentido, si vemos cómo se comporta la industria indumentaria en cada región del país, veremos que en todas las regiones la mayoría de las unidades económicas de esta industria son microempresas. Sin embargo, mientras en el sur del país el 95% de las unidades económicas dedicadas a la industria indumentaria son microempresas, en el centro este porcentaje baja a 78%.
      b-tooltip(triggers="click hover" target="fnref1" placement="top") <p> Dividimos el país en cinco regiones, dentro de la región Norte del país están Baja California, Baja California Sur, Coahuila, Chihuahua, Nuevo León, Sinaloa, Sonora y Tamaulipas. En la región Bajío están Aguascalientes, Colima, Durango, Guanajuato, Jalisco, Michoacán, Nayarit, Querétaro, San Luis Potosí y Zacatecas. En la región Centro están la Ciudad de México, Hidalgo, el Estado de México, Morelos Puebla y Tlaxcala. Finalmente, en la región Sur están Campeche, Chiapas, Guerrero, Oaxaca, Quintana Roo, Tabasco, Veracruz y Yucatán. </p>
      p
        | En el estudio cualitativo, esto se explica por el perfil regional que tienen las actividades industriales del sector en el Sur del país. Las entrevistas informan que las inversiones han sido muy dinámicas en los últimos años, sobre todo en torno a la ciudad de Mérida. Frente a las condiciones laborales imperantes en el sector, el dinamismo y las microempresas plantean un desafío que caracteriza la región: el de la organización de las trabajadoras. Así se explica:
      blockquote
        p.quote-body
          | En los Estados del Sur lo que es complicado es que hay pocas maquilas, digamos, puestas. Y hay mucha maquila que son en las que las personas se llevan los materiales y los hacen en la casa. Eso dificulta muchísimo el seguimiento organizativo porque pues una de las partes importantes del fortalecimiento organizativo es que las mujeres o las personas pues van a un lugar, se identifican, y pueden hablar ¿no? Eso está hecho, pues, a propósito.
        p.quote-footer
          | —ENTREVISTA 13; Activista DHL, Hombre, CDMX
      p
        | El Norte del país es la región que tiene una ligera proporción mayor de unidades económicas con 51 empleados o más, con 3%. La importancia del modelo de industrialización de exportación ha marcado la región y el sector. Allí, la organización de las trabajadoras es históricamente más fuerte, como se aclara en una entrevista:
      blockquote
        p.quote-body
          | Por lo general, aquí ya hay mucha concientización por parte de los trabajadores. Difícilmente, ya casi no despiden a un trabajador sin justificación, aunque sí sucede que las empresas buscan dar lo menos o buscan pretextos a los trabajadores para correrlos o despedirlos sin justificación.
        p.quote-footer
          | —ENTREVISTA 12; Activista DHL, Mujer, Coahuila
      p
        | Sin embargo, los cambios en el perfil industrial de la región -hacia industrias de mayor valor agregado como la aeroespacial o la automotriz- implican que muchas veces las labores de confección se inserten en otras cadenas de valor. Esto plantea, para estas cadenas, problemáticas inherentes a este tipo de producción que resulta difícil visibilizar y, por ende, atender.
      p
        | Pero es el Centro del país la región con mayor porcentaje de empresas pequeñas (de 6 a 50 empleados). En este sentido, pareciera que en el centro y bajío del país hubiera en general una industria un poco más consolidada, aunque en el norte del país haya un poco más de empresas grandes.
      p
        | Las entrevistas describen la región Centro como un espacio en el que se tejen redes entre los inversores y propietarios, y los distintos talleres o industrias del sector. En algunos casos las múltiples empresas funcionan articuladas entre sí. En otros, las redes de producción articulan distintas facilidades de producción de bajo tamaño:
      blockquote
        p.quote-body
          | En algunos lugares, por ejemplo en Tlaxcala, lo que hacían era que ellos, por ejemplo, vivían en Tlaxcala pero iban a Puebla a que les dieran pues todos los insumos, se regresaban a Tlaxcala y ahí durante meses o semanas sobre todo con su familia este… pues maquilaban todas las piezas que les pedían. Entonces regresaban y les pagaban por pieza ¿no? Es decir, había muchas personas que nunca salían de su comunidad, estaban allá en su casa y quedaba más bien lo que trabajaba la familia en esos días. Que nunca veían las condiciones porque nunca iban, nunca tenían contacto.
        p.quote-footer
          | —ENTREVISTA 04; Abogado, Hombre, CDMX
      p
        | La inversión se encuentra, en general, a la baja en la región centro. Algunos casos altisonantes, como las empresas Vaqueros Navarro o Rintex, constituyen hitos en los intentos de organización sindical que tuvieron como epicentro esta región. En el caso del Bajío, el perfil tradicional ha estado orientado a la confección de calzado y el trabajo de marroquinería. Sin embargo, la diversificación del giro en la industria ha dado lugar a fábricas de blancos y ropa, en otros varios tipos de prendas. Pero las malas condiciones de trabajo y los bajos niveles de empleo hacen que la mano de obra busque ocuparse en otros sectores con mejores beneficios.
      blockquote
        p.quote-body
          | Cuando empieza a crecer la industria automotriz, aquí en Aguas Calientes, también eso genera traslados de estas obreras y obreros al área automotriz. Aguascalientes ha crecido mucho en la manufactura de piezas… autopartes. Eso ha, de alguna manera, captado más a este tipo de personas que buscan trabajo.
        p.quote-footer
          | —ENTREVISTA 17; Activista DHL, Mujer, Aguascalientes
      img.responsive(src="assets/Graf_5.jpg")
      p
        | Previamente hablamos de que los hombres suelen trabajar en empresas más grandes que las mujeres, pero esto también depende de la región del país en la que vivan las personas. Por ejemplo, del total de hombres que trabajan en esta industria en el norte, casi el 80% trabaja en empresas grandes, pero sólo el 32% de los hombres que trabajan en el sur trabaja en empresas de ese mismo tamaño. En el caso de las mujeres, casi la mitad de las empleadas en esta industria en el norte del país trabajan en empresas grandes, mientras sólo el 12% de las mujeres del sur trabajan en ese tipo de empresas. Probablemente las pocas empresas grandes que se localizan en el norte sean muy grandes y eso explique la gran cantidad de empleos que representan.
      img.responsive(src="assets/Graf_6.jpg")
      p
        | Esta es información importante ya que las empresas más grandes ofrecen mejores condiciones laborales a sus trabajadoras. Si tomamos el tamaño de la empresa como el único predictor de las condiciones laborales de las personas, el centro del país es el peor lugar para trabajar en la industria si eres hombre, pero el sur del país es el peor lugar para trabajar en la industria si eres mujer. Tanto para hombres como para mujeres, el norte es el mejor lugar para trabajar.
      p
        | El tamaño de una empresa también está, como es de esperarse, muy relacionado con la posibilidad de que las empleadas trabajen en la informalidad. Mientras el 91% y 92% de las mujeres y hombres que trabajan en empresas medianas o grandes tienen un contrato por escrito, pero sólo el 41% y el 3% y 5% de quienes trabajan en microempresa lo tienen. Cabe mencionar que incluso en condiciones de contratación formal, muchas veces estos vínculos tienen numerosos límites. Por ejemplo, las entrevistas informan que los sueldos declarados suelen ser menores que la remuneración final obtenida. Los salarios integrados -incluyendo bonos, incentivos y cuotas de producción- llegan a representar una parte sustancial de los ingresos de las obreras.
      blockquote
        p.quote-body
          | Y si estas van mejorando, va mejorando el bono, entonces se convierte en una variante que incluso llega a igualar; esto es importante que lo sepas, llega a ser el tema de los bonos equivalente al punto seis, punto ocho… y hasta igual al salario base.
        p.quote-footer
          | —ENTREVISTA 04; Abogado, Hombre, CDMX
      p
        | Otro límite es que muchos de estos contratos están orientados hacia la protección patronal. En algunos casos, las trabajadoras no saben cuáles son las características de sus vínculos con las empresas.
      blockquote
        p.quote-body
          | (…) en términos generales lo que te puedo decir es que de la muestra que nosotros analizamos, hay nada más un contrato independiente del cual las trabajadoras conocen su contenido, saben que tienen un contrato que tienen un sindicato que las representa; pero el resto de los otros contratos, no.
        p.quote-footer
          | — ENTREVISTA 10; Activista DHL, Mujer, CDMX
      p
        | Ante estas situaciones, la organización de las trabajadoras es un punto central para tender las formas de contratación y las dinámicas laborales. Este es un punto muy reñido, las informantes señalan que estos procesos tienden a ser muy resistidos por las empresas. Muchas veces, incluso, llegan a cerrar sus operaciones antes que aceptar la organización independiente:
      blockquote
        p.quote-body
          | (…) mientras no haya un esfuerzo de organización en proceso, las empresas realmente no tienen ningún incentivo para hacer esto del cambio de razón social o cerrar operación. También, hay ciertas partes de la cadena de suministros en la industria en las que pasa más regularmente esto. Confección es una de las áreas en las que suele suceder con mayor facilidad, porque el tipo de inversión en la industria se puede mover con mucha más facilidad. Una inversión no es tan fuerte. Cosa que no pasa, por ejemplo, en la parte de la generación del textil. Es una inversión mucho más grande tener máquinas que hagan telas y es más difícil para esas empresas solo desaparecer o cambiar de un lugar a otro.
        p.quote-footer
          | — ENTREVISTA 10; Activista DHL, Mujer, CDMX
      p
        | Esta precisión sobre el dinamismo en ciertas ramas y tamaños de industria son un dato cualitativo importante para contrastar con las prestaciones que brinda el sector a quienes allí laboran.
      p
        | En México, la mayoría de las unidades económicas que se dedican a la industria indumentaria son microempresas, pero en algunas regiones del país hay más microempresas que en otras. Esto en parte condiciona las condiciones laborales de las personas que laboran en esta industria. Como se discutió previamente, en el norte del país las trabajadoras dedicadas a esta industria trabajan proporcionalmente más en empresas medianas o grandes. Asimismo, mientras casi el 80% de los hombres del norte que trabajan en la industria indumentaria trabajan en empresas grandes, sólo el 48% de las mujeres trabajan en el mismo tipo de empresas.
      p
        | Todo esto, aunado a la diferenciación de la industria por región del país, puede explicar en parte la brecha salarial. Tomando en cuenta sólo los salarios, el mejor lugar para trabajar en la industria indumentaria es el norte del país y el peor el sur. Lo que es interesante considerando que más hombres trabajan en microempresas en el sur que en el centro del país. La brecha de género es muy notoria. Mientras en el sur el 74.3% de las mujeres trabajadoras de la industria gana un salario mínimo o menos, sólo el 38% de los hombres de la región ganan lo mismo.
      img.responsive(src="assets/Graf_7.jpg")
      p
        | Un aporte importante para entender cómo se despliegan las desigualdades entre hombres y mujeres es el propuesto por una de las entrevistadas. No es una diferenciación visible al analizar el sueldo que se paga por un mismo trabajo, más bien hay sectores que son completamente asignados a obreras. Estos son los de menor paga y de mayores riesgos de salud. Al mismo tiempo, la superposición de jornadas impide a las mujeres tomar más trabajo que, de acuerdo con el esquema de paga variable, afecta directamente su nivel de ingresos.
      blockquote
        p.quote-body
          | (…) cómo se presenta la disparidad salarial dentro de la industria. Usualmente en el imaginario colectivo tenemos o entendemos la disparidad salarial de dos personas haciendo el mismo trabajo y recibiendo diferentes remuneraciones. Y, por lo menos en la parte de la confección es algo que no sucede así. O sea, obreros y obreras en el piso de producción suelen ganar lo mismo mientras tengan el mismo nivel, responsabilidad o puesto. A los incentivos de producción… Lo que varía no es el salario base, sino el trabajo a destajo. Como trabajan por bulto por nivel de producción, entonces ahí sí tiene más que ver que, por ejemplo, hay fábricas que permiten hacer horas extras –que no las pagan como horas extras pero la gente se queda cosiendo– entonces quienes tienen más posibilidad de hacer eso son los hombres. Porque no tienen una responsabilidad en casa a la cual regresar, con todo el tema del trabajo de cuidado. Y luego, la otra disparidad que es como más fuerte es que los hombres se insertan en partes del proceso de producción que son mejor remuneradas. Esto es corte. Confección es lo peor remunerado, digamos; las escalas salariales más bajas. Mientras que los hombres están en actividades salariales mejor pagadas como corte, sublimación, decorado y este otro tipo de labores que suelen tener salarios más altos.
        p.quote-footer
          | —ENTREVISTA 14; Activista DHL, Mujer, Estado de Hidalgo
      p
        | El análisis descriptivo de los datos de la última versión de la ENOE y las entrevistas realizadas en este periodo refuerzan lo dicho en la literatura sobre que las condiciones laborales en la industria indumentaria son bastante más precarias que las condiciones en otras industrias manufactureras.
      p
        | No obstante, no todas los trabajadores viven el mismo nivel de precariedad y esto depende en parte de su género, del tamaño de la empresa en la que trabajen y de la región en la que trabajen. Las mujeres trabajadoras están casi siempre en peores condiciones laborales que sus contrapartes hombres, pero esto no necesariamente se explica por una diferencia en el salario por el mismo trabajo, sino por la intersección en otra serie de temas dentro de los que se encuentran; el trabajo de cuidados, las estrategias de las empresas para evitar la organización sindical y para reclutar trabajadoras. Si queremos contribuir de forma positiva a las condiciones laborales de las trabajadoras de la industria debemos evaluar todo este amplio panorama de variables que explican en parte las condiciones laborales precarias de las empleadas de la industria indumentaria en México.

</template>
<script>

export default {
  name:"Chapter3"
}
</script>